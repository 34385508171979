import React from 'react'
import PropTypes from 'prop-types'

import { Skeleton, Divider, Anchor } from 'lib'
import { colors } from 'assets/themes'

const { Link } = Anchor

const TermsConditionsOfUseEn = ({ contact, loading }) => {
  const { address, zipCode, city, email } = contact

  return (
    <>
      <Anchor affix={false}>
        <Link href="#site-presentation" title="1. Presentation of the site" />
        <Link
          href="#general-conditions-use"
          title="2. General conditions of use of the site and the services offered"
        />
        <Link href="#services-descriptions" title="3. Description of services provided" />
        <Link
          href="#contractual-limitations-technical-data"
          title="4. Contractual limitations on technical data"
        />
        <Link href="#intelectual-property-counterfeits" title="5. Intellectual property and counterfeiting" />
        <Link href="#limitations-liability" title="6. Limitations of liability" />
        <Link href="#management-personal-data" title="7. Management of personal data" />
        <Link href="#hypertext-links-cookies" title="8. Hypertext links and cookies" />
        <Link href="#applicable-law-jurisdiction" title="9. Applicable law and jurisdiction" />
        <Link href="#main-laws" title="10. The main laws concerned" />
        <Link href="#glossary" title="11. Glossary" />
      </Anchor>

      <h1>Legal information</h1>
      <Divider margin={6} colors={colors.red} />
      <Skeleton active round loading={loading} paragraph={{ rows: 6 }}>
        <h2 id="site-presentation">1. Presentation of the site</h2>
        <Divider margin={2} opacity={0.2} colors={colors.grey} />
        <p>
          In accordance with Article 6 of Law 2004-575 of 21 June 2004 on confidence in the digital economy,
          users of the <a href="https://atelierbreze.fr/">https://atelierbreze.fr</a> website are informed of
          the identity of the various parties involved in its creation and monitoring :
        </p>
        <p>
          <strong>Owner</strong> : Pierre Brézé – Micro 88334385700016 – {address} {zipCode} {city}
          <br />
          <strong>Creator</strong> : <a href="https://benjamin-alexandre.fr">Benjamin Alexandre</a>
          <br />
          <strong>Head of publication</strong> : Pierre Brézé – {email}
          <br />
          The person responsible for publication is a natural person or a legal entity.
          <br />
          <strong>Webmaster</strong> : Benjamin Alexandre – benjamin.alexandre71@orange.fr
          <br />
          <strong>Hosting</strong> : OVH – 2 rue Kellermann 59100 Roubaix
          <br />
          Credits : <br />
          The legal notice template is offered by Subdelirium.com{' '}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.subdelirium.com/generateur-de-mentions-legales/"
            alt="generateur de mentions légales"
          >
            Legal notice generator
          </a>
        </p>
      </Skeleton>

      <Skeleton active round loading={loading} paragraph={{ rows: 3 }}>
        <h2 id="general-conditions-use">2. General conditions of use of the site and the services offered</h2>
        <Divider margin={2} opacity={0.2} colors={colors.grey} />
        <p>
          The use of the site <a href="https://atelierbreze.fr/">https://atelierbreze.fr</a> involves full and
          complete acceptance of the general conditions of use described below. These conditions of use may be
          modified or supplemented at any time, the terms and users of the site{' '}
          <a href="https://atelierbreze.fr/">https://atelierbreze.fr</a> are therefore invited to consult them
          on a regular basis.
        </p>
        <p>
          This site is normally accessible to users at all times. An interruption due to technical maintenance
          can however be decided by Pierre Brézé, who will then endeavour to inform users in advance of the
          dates and times of the intervention.
        </p>
        <p>
          The site <a href="https://atelierbreze.fr/">https://atelierbreze.fr</a> is updated regularly by
          Pierre Brézé. In the same way, the legal notices can be modified at any time. moment: they are
          nevertheless binding on the user, who is invited to refer to them most often. possible in order to
          get acquainted with it.
        </p>
      </Skeleton>
      <Skeleton active round loading={loading} paragraph={{ rows: 3 }}>
        <h2 id="services-descriptions">3. Description of services provided</h2>
        <Divider margin={2} opacity={0.2} colors={colors.grey} />
        <p>
          The purpose of the site <a href="https://atelierbreze.fr/">https://atelierbreze.fr</a> is to provide
          information concerning all the company's activities.
        </p>
        <p>
          Pierre Brézé endeavours to provide on the site{' '}
          <a href="https://atelierbreze.fr/">https://atelierbreze.fr</a> information also precise as possible.
          However, it cannot be held responsible for omissions, omissions, omissions, omissions, omissions,
          omissions, omissions, omissions, omissions, omissions, omissions, omissions, omissions, omissions,
          omissions. inaccuracies and shortcomings in the update, whether caused by itself or by third parties
          partners who provide it with this information.
        </p>
        <p>
          All the information on the site
          <a href="https://atelierbreze.fr/">https://atelierbreze.fr</a> are given as a title and are subject
          to change. Furthermore, the information on the site is subject to change.
          <a href="https://atelierbreze.fr/">https://atelierbreze.fr</a> are not exhaustive. They are given
          subject to modifications that have been made since they were put online.
        </p>
      </Skeleton>
      <Skeleton active round loading={loading} paragraph={{ rows: 2 }}>
        <h2 id="contractual-limitations-technical-data">4. Contractual limitations on technical data</h2>
        <Divider margin={2} opacity={0.2} colors={colors.grey} />
        <p>The site uses JavaScript technology.</p>
        <p>
          The website cannot be held responsible for any material damage related to the use of the site.
          Furthermore, the user of the site undertakes to access the site using recent material, not to
          virus-free and with an up-to-date, state-of-the-art browser.
        </p>
      </Skeleton>
      <Skeleton active round loading={loading} paragraph={{ rows: 4 }}>
        <h2 id="intelectual-property-counterfeits">5. Intellectual property and counterfeiting</h2>
        <Divider margin={2} opacity={0.2} colors={colors.grey} />
        <p>
          Pierre Brézé is the owner of the intellectual property rights or holds the rights of use. on all the
          elements accessible on the site, in particular texts, images, graphics, logo, icons, sounds,
          software.
        </p>
        <p>
          Any reproduction, representation, modification, publication, adaptation of all or part of the
          elements of the site, regardless of the means or process used, is prohibited, unless authorised by
          the owner. written in advance by : Pierre Brézé.
        </p>
        <p>
          Any unauthorised use of the site or of any of the elements it contains will be considered to
          constitute an infringement and prosecuted in accordance with the provisions of the articles L.335-2
          and following of the Intellectual Property Code.
        </p>
      </Skeleton>
      <Skeleton active round loading={loading} paragraph={{ rows: 6 }}>
        <h2 id="limitations-liability">6. Limitations of liability</h2>
        <Divider margin={2} opacity={0.2} colors={colors.grey} />
        <p>
          Pierre Brézé cannot be held responsible for any direct or indirect damage caused to the equipment.
          of the user, when accessing the https://atelierbreze.fr website, and resulting either from the use
          of equipment that does not meet the specifications set out in point 4, either to the appearance of a
          bug or incompatibility.
        </p>
        <p>
          Pierre Brézé shall also not be held liable for indirect damages (such as for example that a loss of
          market or loss of opportunity) resulting from the use of the site .
          <a href="https://atelierbreze.fr/">https://atelierbreze.fr</a>.
        </p>
        <p>
          Interactive spaces (possibility to ask questions in the contact area) are at the availability to
          users. Pierre Brézé reserves the right to delete, without formal notice any content deposited in
          this space that contravenes the legislation applicable in France. France, in particular the
          provisions on data protection. Where applicable, Pierre Brézé also reserves the right to bring civil
          liability claims against and/or user, in particular in the case of a racist, insulting, defamatory
          or libellous message, or pornographic, whatever the medium used (text, photography...).
        </p>
      </Skeleton>
      <Skeleton active round loading={loading} paragraph={{ rows: 8 }}>
        <h2 id="management-personal-data">7. Management of personal data</h2>
        <Divider margin={2} opacity={0.2} colors={colors.grey} />
        <p>
          In France, personal data is notably protected by law n° 78-87 of 6 January 1978, law n° 2004-801 of
          6 August 2004, article L. 226-13 of the Penal Code and the European Directive of the 24 October
          1995.
        </p>
        <p>
          When using the site <a href="https://atelierbreze.fr/">https://atelierbreze.fr</a>, can be
          collected: the URL of the links through which the user accessed the site{' '}
          <a href="https://atelierbreze.fr/">https://atelierbreze.fr</a>, the access provider of the user, the
          user's Internet Protocol (IP) address.
        </p>
        <p>
          {' '}
          In any event, Pierre Brézé does not collect personal information relating to the user only for the
          need of certain services offered by the site .
          <a href="https://atelierbreze.fr/">https://atelierbreze.fr</a>. The user provides these information
          in full knowledge of the facts, in particular when it carries out its own seizure. It is then
          specified to the user of the site .<a href="https://atelierbreze.fr/">https://atelierbreze.fr</a>{' '}
          whether or not the obligation to provide this information.
        </p>
        <p>
          In accordance with the provisions of articles 38 and following of law 78-17 of 6 January 1978
          relating to to data processing, files and freedoms, every user has the right to access, modify,
          delete or delete data correction and opposition to the personal data concerning him/her, by making
          his/her request written and signed, accompanied by a copy of the identity document with signature of
          the holder of the the document, specifying the address to which the reply should be sent.
        </p>
        <p>
          No personal information of the user of the site .
          <a href="https://atelierbreze.fr/">https://atelierbreze.fr</a> is published without the knowledge of
          the user, exchanged, transferred, ceded or sold on any medium whatsoever to third parties. Only the
          hypothesis of the repurchase of Pierre Brézé and his rights would allow the transmission of the said
          information to the potential purchaser, who would in turn be subject to the same obligation to
          retain the information. and modification of data with regard to the user of the site .
          <a href="https://atelierbreze.fr/">https://atelierbreze.fr</a>.
        </p>
        <p>
          The databases are protected by the provisions of the law of 1 July 1998 transposing Directive 96/9
          of 11 March 1996 on the legal protection of databases.
        </p>
      </Skeleton>
      <Skeleton active round loading={loading} paragraph={{ rows: 8 }}>
        <h2 id="hypertext-links-cookies">8. Liens hypertextes et cookies</h2>
        <Divider margin={2} opacity={0.2} colors={colors.grey} />
        <p>
          The site <a href="https://atelierbreze.fr/">https://atelierbreze.fr</a> contains a certain number of
          hyperlinks to other sites, set up with the authorisation of Pierre Brézé. However, Pierre Brézé is
          not in a position to verify the content of the sites thus visited, and will therefore not assume any
          liability for this fact.
        </p>
        <p>
          Navigation on the site <a href="https://atelierbreze.fr/">https://atelierbreze.fr</a> is which may
          cause the installation of cookie(s) on the user's computer. A cookie is a small file, which does not
          allow user identification, but which records information relating to the navigation of a computer on
          a site. The data thus obtained are intended to facilitate subsequent navigation on the site, and are
          also intended to allow various measures of attendance.
        </p>
        <p>
          Refusal to install a cookie may make it impossible to access certain services. However, the user can
          configure his computer in the following way, to refuse the installation of cookies :
        </p>
        <p>
          Under Internet Explorer: tool tab (gearwheel pictogram in the top right-hand corner) / options
          internet. Click Privacy and choose Block all cookies. Validate on Ok.
        </p>
        <p>
          Under Firefox: at the top of the browser window, click on the Firefox button, then go to the Options
          tab. Click on the Privacy tab. Set the Retention Rules to : use the custom settings for history.
          Finally uncheck it to disable cookies.
        </p>
        <p>
          Under Safari: Click on the menu icon in the top right-hand corner of the browser (symbolised by a
          cog). Select Settings. Click View Advanced Settings. In the Under "Privacy", click Content settings.
          In the "Cookies" section, you can block cookies.
        </p>
        <p>
          Under Chrome: Click on the menu icon (symbolized by three horizontal lines). Select Settings. Click
          Show Advanced Settings. In the In the "Privacy" section, click on preferences. In the
          "Confidentiality" tab, you can block cookies.
        </p>
      </Skeleton>
      <Skeleton active round loading={loading} paragraph={{ rows: 1 }}>
        <h2 id="applicable-law-jurisdiction">9. Applicable law and jurisdiction</h2>
        <Divider margin={2} opacity={0.2} colors={colors.grey} />
        <p>
          Any dispute relating to the use of the site .
          <a href="https://atelierbreze.fr/">https://atelierbreze.fr</a> is subject to French law. Exclusive
          jurisdiction is given to the competent courts of Paris.
        </p>
      </Skeleton>

      <Skeleton active round loading={loading} paragraph={{ rows: 2 }}>
        <h2 id="main-laws">10. The main laws concerned</h2>
        <Divider margin={2} opacity={0.2} colors={colors.grey} />
        <p>
          Law no. 78-17 of 6 January 1978, as amended by Law no. 2004-801 of 6 August 2004 relating to
          information technology, files and freedoms.
        </p>
        <p> Law No. 2004-575 of 21 June 2004 for confidence in the digital economy.</p>
      </Skeleton>

      <Skeleton active round loading={loading} paragraph={{ rows: 2 }}>
        <h2 id="glossary">11. Glossary</h2>
        <Divider margin={2} opacity={0.2} colors={colors.grey} />
        <p>User: Internet user logging on, using the abovementioned site.</p>
        <p>
          Personal information: "information that allows, in any form whatsoever, directly or indirectly, the
          identification of the natural persons to whom they apply". (Article 4 of Law No. 78-17 of 6 January
          1978).
        </p>
      </Skeleton>
    </>
  )
}

TermsConditionsOfUseEn.propTypes = {
  contact: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired
}

export default TermsConditionsOfUseEn
