import { combineReducers } from 'redux'
import { connectRouter, LOCATION_CHANGE } from 'connected-react-router'
import {
  language as languageReducer,
  contact as contactReducer,
  gallery as galleryReducer,
  albums as albumsReducer,
  albumReducer,
  publications as publicationsReducer,
  errorReducer,
  loadingReducer
} from 'store/reducer'

import history from './history'

const appReducer = combineReducers({
  gallery: galleryReducer,
  albums: albumsReducer,
  album: albumReducer,
  publications: publicationsReducer,
  contact: contactReducer,
  language: languageReducer,
  error: errorReducer,
  loading: loadingReducer,
  router: connectRouter(history)
})

const rootReducer = (state, action) => {
  if (action.type === LOCATION_CHANGE) {
    const { language, contact, gallery, albums, album, publications } = state
    state = { language, contact, gallery, albums, album, publications }
  }
  return appReducer(state, action)
}

export default rootReducer
