import React, { useEffect, useState } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'

import history from 'app/history'
import * as constants from 'utils/route/constants'

import { useWindowWidth, isSizeBetween, BREAKPOINTS } from 'utils/tools/page'
import loadingSelector from 'utils/redux/selectors'
import { Row, Col, ScrollLink, Divider, Svg, Spin } from 'lib'
import svg from 'assets/svg'
import { colors } from 'assets/themes'
import { FETCH_CONTACT } from 'store/constants'

const SVGWrapper = styled.div`
  width: 100%;
  padding: 10px;
`

const SVG = styled(Svg)`
  fill: ${colors.white};
`

const Footer = ({ intl, contact, loading, showLinks, showOtherItem }) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(isSizeBetween(0, BREAKPOINTS.SM))
  }, [useWindowWidth()])

  const redirectToTermsConditions = () => history.push(constants.TERMS_CONDITIONS_OF_USE)

  const redirectToPrivacyPolicy = () => history.push(constants.PRIVACY_POLICY)

  const redirectHome = () => history.push(constants.HOME)

  const redirectAlbums = () => history.push(constants.ALBUMS)

  const { address, zipCode, city, email, phone } = contact

  return (
    <div id="footer">
      <Divider colors={colors.red} height={50} margin={0} />
      <div className="footer-container">
        <Row align="center">
          <Col sm={10} xs={18}>
            <a href="https://atelierbreze.fr" className="footer-logo">
              <SVGWrapper>
                <SVG svg={svg.logo} />
              </SVGWrapper>
            </a>
          </Col>
        </Row>
        <Row justify="space-between" align="start">
          <Col sm={12} xs={24} className="footer-contact">
            <Row align="start" justify="space-between" style={{ height: '100%' }}>
              <Col span={24}>
                <h5>
                  <FormattedMessage id="menu.contact" />
                </h5>
              </Col>
              {loading ? (
                <Col span={24}>
                  <Row justify="center">
                    <Spin color={colors.red} />
                  </Row>
                </Col>
              ) : (
                <>
                  <Col span={24}>
                    {address} <br />
                    {zipCode} {city}
                  </Col>
                  <Col span={24}>{email}</Col>
                  <Col span={24}>{phone && phone.split('').map((x, i) => (i % 2 === 0 ? ` ${x}` : x))}</Col>
                  <Col span={24}>
                    <FormattedMessage id="contact.details.only_appointment" />
                  </Col>
                </>
              )}
            </Row>
          </Col>
          {isMobile && <Divider colors={colors.red} height={2} />}
          <Col sm={12} xs={24} className="footer-links">
            <div className="footer-links-wrapper">
              <div>
                <h5>
                  <FormattedMessage id="footer.links" />
                </h5>
              </div>
              {showLinks ? (
                <>
                  <Row justify="center" gutter={[0, 4]}>
                    <Col span={12}>
                      <ScrollLink className="footer-links-item" linkTo="home">
                        <div>{intl.formatMessage({ id: 'menu.home' })}</div>
                      </ScrollLink>
                    </Col>
                  </Row>
                  <Row justify="center" gutter={[0, 4]}>
                    <Col span={12}>
                      <ScrollLink className="footer-links-item" linkTo="presentation">
                        <div>{intl.formatMessage({ id: 'menu.presentation' })}</div>
                      </ScrollLink>
                    </Col>
                  </Row>
                  <Row justify="center" gutter={[0, 4]}>
                    <Col span={12}>
                      <ScrollLink className="footer-links-item" linkTo="services">
                        <div>{intl.formatMessage({ id: 'menu.services' })}</div>
                      </ScrollLink>
                    </Col>
                  </Row>
                  <Row justify="center" gutter={[0, 4]}>
                    <Col span={12}>
                      <ScrollLink className="footer-links-item" linkTo="gallery">
                        <div>{intl.formatMessage({ id: 'menu.gallery' })}</div>
                      </ScrollLink>
                    </Col>
                  </Row>
                  {/* <Row justify="center" gutter={[0, 4]}>
                    <Col span={12}>
                      <ScrollLink className="footer-links-item" linkTo="opinion">
                        <div>{intl.formatMessage({ id: 'menu.opinion' })}</div>
                      </ScrollLink>
                    </Col>
                  </Row> */}
                  <Row justify="center" gutter={[0, 4]}>
                    <Col span={12}>
                      <ScrollLink className="footer-links-item" linkTo="contact">
                        <div>{intl.formatMessage({ id: 'menu.contact' })}</div>
                      </ScrollLink>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row justify="center" gutter={[0, 4]}>
                    <Col span={12} className="footer-links-item" onClick={redirectHome}>
                      <div>{intl.formatMessage({ id: 'go_back' })}</div>
                    </Col>
                  </Row>
                  {showOtherItem && (
                    <Row justify="center" gutter={[0, 4]}>
                      <Col span={12} className="footer-links-item" onClick={redirectAlbums}>
                        <div>{intl.formatMessage({ id: 'go_back_album' })}</div>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
        {isMobile && <Divider colors={colors.red} height={2} />}
        <div className="footer-legacy">
          <Row justify="space-around" align="middle">
            <Col sm={8} xs={24} className="footer-link" onClick={redirectToTermsConditions}>
              <div>
                <FormattedMessage id="footer.terms_conditions" />
              </div>
            </Col>
            <Col sm={8} xs={24} className="footer-link" onClick={redirectToPrivacyPolicy}>
              <div>
                <FormattedMessage id="footer.privacy_policy" />
              </div>
            </Col>
            {/* <Col sm={8} xs={24}>
              <FormattedMessage id="footer.sitemap" />
            </Col> */}
          </Row>
        </div>
        {isMobile && <Divider colors={colors.red} height={2} />}
        <div className="footer-copyright">
          <Row justify="space-between" align="middle">
            <Col span={24}>
              ©2021 Atelier Brézé {isMobile ? <br /> : '| '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://benjamin-alexandre.fr"
                className="footer-copyright-link"
              >
                Benjamin Alexandre
              </a>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

Footer.defaultProps = {
  showLinks: true,
  showOtherItem: false
}

Footer.propTypes = {
  intl: PropTypes.shape().isRequired,
  contact: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  showLinks: PropTypes.bool,
  showOtherItem: PropTypes.bool
}

const mapStateToProps = (state) => ({
  contact: state.contact,
  loading: loadingSelector([FETCH_CONTACT])(state)
})

const withIntl = injectIntl(Footer)
export default connect(mapStateToProps)(withIntl)
