import React, { useEffect, useState, useCallback } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import history from 'app/history'
import svg from 'assets/svg'
import { Row, Col, Icon, ICONS_LIST, Svg } from 'lib'
import { useWindowWidth, isSizeBetween, BREAKPOINTS } from 'utils/tools/page'
import * as constants from 'utils/route/constants'
import { colors } from 'assets/themes'
import img from 'assets/images'
import lang from 'utils/intl/lang'
import { changeLanguage } from 'store/actions'

const SVGWrapper = styled.div`
  padding: 10px 10px;
  margin-left: 50px;
`

const SVG = styled(Svg)`
  fill: #fff;
`

const ReducedMenu = ({ intl, currentLang, showOtherItem, actions }) => {
  const [showMinimalMenu, setShowMinimalMenu] = useState(false)
  const [overlayOpened, isOverlayOpened] = useState(false)
  const openOverlay = useCallback(() => isOverlayOpened(true), [])
  const closeOverlay = useCallback(() => isOverlayOpened(false), [])

  const handleChangeLang = async (e) => {
    e.preventDefault()
    switch (currentLang) {
      case lang.GB:
        await actions.onChangeLang(lang.FR)
        break
      case lang.FR:
      default:
        await actions.onChangeLang(lang.GB)
        break
    }
  }

  const renderFlag = () => {
    switch (currentLang) {
      case lang.GB:
        return (
          <div className="flag-wrapper" onClick={handleChangeLang} role="button" aria-hidden="true">
            <img src={img.frenchFlag.url} alt={img.frenchFlag.alt} />
          </div>
        )
      case lang.FR:
      default:
        return (
          <div className="flag-wrapper" onClick={handleChangeLang} role="button" aria-hidden="true">
            <img src={img.englishFlag.url} alt={img.frenchFlag.alt} />
          </div>
        )
    }
  }

  useEffect(() => {
    setShowMinimalMenu(isSizeBetween(0, BREAKPOINTS.LG))
  }, [useWindowWidth()])

  const redirectHome = () => history.push(constants.HOME)

  const redirectAlbums = () => history.push(constants.ALBUMS)

  return showMinimalMenu ? (
    <>
      <div className={overlayOpened ? 'menu-overlay menu-overlay-open' : 'menu-overlay'}>
        <Icon
          className="menu-overlay-close-btn"
          color={colors.white}
          name={ICONS_LIST.close}
          onClick={closeOverlay}
        />
        <div className="menu-overlay-content">
          <Col span={24} onClick={redirectHome}>
            <span className="menu-link">{intl.formatMessage({ id: 'go_back' })}</span>
          </Col>
          {showOtherItem && (
            <Col span={24} onClick={redirectAlbums}>
              <span className="menu-link">{intl.formatMessage({ id: 'go_back_album' })}</span>
            </Col>
          )}
        </div>
      </div>
      {!overlayOpened && (
        <div className="menu-overlay-open-btn">
          <Icon className="menu-icon" name={ICONS_LIST.menu} color={colors.white} onClick={openOverlay} />
        </div>
      )}
    </>
  ) : (
    <div className={'menu menu-active'}>
      <Row className="menu-content" justity="space-between" align="middle">
        <Col span={2}>
          <a href="https://atelierbreze.fr" className="menu-logo">
            <SVGWrapper>
              <SVG svg={svg.logo} />
            </SVGWrapper>
          </a>
        </Col>
        <Col span={18} offset={4}>
          <Row gutter={24} justify="end" align="middle">
            <Col span={4} onClick={redirectHome}>
              <span className="reduced-menu-link">{intl.formatMessage({ id: 'go_back' })}</span>
            </Col>
            {showOtherItem && (
              <Col span={4} onClick={redirectAlbums}>
                <span className="reduced-menu-link">{intl.formatMessage({ id: 'go_back_album' })}</span>
              </Col>
            )}
            <Col span={2} onClick={handleChangeLang}>
              {renderFlag()}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

ReducedMenu.defaultProps = {
  currentLang: lang.FR,
  showOtherItem: false
}

ReducedMenu.propTypes = {
  intl: PropTypes.shape().isRequired,
  actions: PropTypes.shape().isRequired,
  currentLang: PropTypes.string,
  showOtherItem: PropTypes.bool
}

const mapStateToProps = (state) => ({
  currentLang: state.language
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    onChangeLang: (locale) => dispatch(changeLanguage(locale))
  }
})

const withIntl = injectIntl(ReducedMenu)

export default connect(mapStateToProps, mapDispatchToProps)(withIntl)
