import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Row, Col, Spin, PageTitle, GalleryCard, BackTop, ReducedMenu, Footer } from 'lib'
import { colors } from 'assets/themes'
import loadingSelector from 'utils/redux/selectors'
import lang from 'utils/intl/lang'

import { fetchAlbum, fetchPublications, fetchContact } from 'store/actions'
import { FETCH_ALBUM, FETCH_PUBLICATIONS } from 'store/constants'

const Publications = ({ intl, actions, album, publications, loading, ...props }) => {
  useEffect(() => {
    actions.initData(props.match.params.id)
  }, [props.match.params.id])

  return (
    <>
      <ReducedMenu showOtherItem />
      <div className="albums-wrapper">
        <BackTop />

        {loading ? (
          <Col span={24}>
            <Row justify="center">
              <Spin color={colors.red} size="large" margin />
            </Row>
          </Col>
        ) : (
          <>
            <div>
              <PageTitle
                title={props.currentLang === lang.FR ? album.titleFr : album.titleEn || album.titleFr}
                offsetAnimation={0}
              />
            </div>
            <Row gutter={[48, 48]} justify="center" align="middle">
              {publications.items.map((item) => (
                <Col lg={6} sm={12} xs={24} key={item.id}>
                  <GalleryCard element={item} />
                </Col>
              ))}
            </Row>
          </>
        )}
      </div>
      <Footer showLinks={false} showOtherItem />
    </>
  )
}

Publications.propTypes = {
  intl: PropTypes.shape().isRequired,
  match: PropTypes.any,
  actions: PropTypes.shape().isRequired,
  album: PropTypes.shape().isRequired,
  publications: PropTypes.shape().isRequired,
  currentLang: PropTypes.string,
  loading: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  album: state.album,
  publications: state.publications,
  currentLang: state.language,
  loading: loadingSelector([FETCH_PUBLICATIONS, FETCH_ALBUM])(state)
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    initData: (id) => {
      Promise.all([dispatch(fetchAlbum(id)), dispatch(fetchPublications(id)), dispatch(fetchContact())])
    }
  }
})

const withIntl = injectIntl(Publications)
export default connect(mapStateToProps, mapDispatchToProps)(withIntl)
