import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const ScollToTop = ({ children, router }) => {
  const { location } = router
  useEffect(() => {
    if (window) window.scrollTo(0, 0)
  }, [location.key])

  return <>{children}</>
}

ScollToTop.propTypes = {
  children: PropTypes.any,
  router: PropTypes.shape().isRequired
}

const mapStateToProps = (state) => ({
  router: state.router
})

export default connect(mapStateToProps)(ScollToTop)
