import React, { useEffect, useState } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

import { useWindowWidth, isSizeBetween, BREAKPOINTS } from 'utils/tools/page'
import { Row, Col, Layout, PageTitle, Divider, ScrollLink } from 'lib'
import img from 'assets/images'
import { colors } from 'assets/themes'

const Presentation = ({ intl }) => {
  const [isLaptop, setIsLaptop] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsLaptop(isSizeBetween(BREAKPOINTS.LG, BREAKPOINTS.XL))
    setIsTablet(isSizeBetween(BREAKPOINTS.SM, BREAKPOINTS.LG))
    setIsMobile(isSizeBetween(0, BREAKPOINTS.SM))
  }, [useWindowWidth()])

  return (
    <Layout id="presentation">
      <PageTitle
        title={intl.formatMessage({
          id: 'menu.presentation'
        })}
      />
      <div className="profile">
        <Row gutter={[0, 8]} style={{ margin: 0 }}>
          {!isLaptop && !isTablet && !isMobile && (
            <Col span={24}>
              <Row align="middle">
                <Col span={9}>
                  <Row>
                    <Col span={23}>
                      <img src={img.presentation.url} alt={img.presentation.alt} className="profile-image" />
                    </Col>
                    <Col span={1}>
                      <div className="profile-image-vertical-border" />
                    </Col>
                  </Row>
                </Col>
                <Col span={14} offset={1}>
                  <Row gutter={[0, 32]} style={{ margin: 0 }}>
                    <Col span={24}>
                      <h2>
                        PIERRE BRÉZÉ, <br />
                        <FormattedMessage id="presentation.title" />
                      </h2>
                    </Col>
                    <Col span={24}>
                      <FormattedMessage id="presentation.desc_1" />
                    </Col>
                    <Col span={24}>
                      <FormattedMessage id="presentation.desc_2" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          )}

          {isLaptop && (
            <>
              <Col span={24}>
                <h2>
                  PIERRE BRÉZÉ, <br />
                  <FormattedMessage id="presentation.title" />
                </h2>
              </Col>
              <Col span={24}>
                <Row align="middle">
                  <Col span={7}>
                    <Row>
                      <Col span={23}>
                        <img
                          src={img.presentation.url}
                          alt={img.presentation.alt}
                          className="profile-image"
                        />
                      </Col>
                      <Col span={1}>
                        <div className="profile-image-vertical-border" />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={16} offset={1}>
                    <Row gutter={[0, 32]} style={{ margin: 0 }}>
                      <Col span={24}>
                        <FormattedMessage id="presentation.desc_1" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <FormattedMessage id="presentation.desc_2" />
              </Col>
            </>
          )}

          {(isTablet || isMobile) && (
            <>
              <Col span={24}>
                <Row align="middle">
                  <Col md={10} xs={14} style={{ margin: 'auto' }}>
                    <img src={img.presentation.url} alt={img.presentation.alt} className="profile-image" />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Divider colors={colors.red} margin={2} />
              </Col>
              <Col span={24}>
                <h2>
                  PIERRE BRÉZÉ, <br />
                  <FormattedMessage id="presentation.title" />
                </h2>
              </Col>
              <Col span={24}>
                <FormattedMessage id="presentation.desc_1" />
              </Col>
              <Col span={24}>
                <FormattedMessage id="presentation.desc_2" />
              </Col>
            </>
          )}
          <Col span={24}>
            <FormattedMessage id="presentation.desc_3" />
          </Col>
          <Col span={24}>
            <FormattedMessage id="presentation.desc_4" />
          </Col>
          <Col span={24}>
            <Row justify="center">
              <ScrollLink linkTo="contact">
                <button type="button" className="profile-contact-button">
                  <FormattedMessage id="presentation.contact" />
                </button>
              </ScrollLink>
            </Row>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

Presentation.propTypes = {
  intl: PropTypes.shape().isRequired
}

export default injectIntl(Presentation)
