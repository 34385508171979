import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

const defaultSpin = ({ color, size, ...props }) => (
  <Spin
    size={size}
    indicator={<LoadingOutlined style={{ fontSize: size === 'large' ? 50 : 24, color }} spin />}
    style={{ margin: props.margin ? '150px auto' : 'initial' }}
  />
)

defaultSpin.defaultProps = {
  size: 'default',
  margin: false
}

defaultSpin.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'default', 'large']),
  margin: PropTypes.bool
}

export default defaultSpin
