import React, { useState } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { send } from 'emailjs-com'

import { Row, Col, Form, Input, message } from 'lib'

const { TextArea } = Input

const ContactForm = ({ intl }) => {
  const [form] = Form.useForm()
  const [formState, setForm] = useState({})

  const onChange = (e) => {
    const { name, value } = e.target
    setForm({
      ...formState,
      [name]: value
    })
  }

  const onSubmit = () => {
    send(
      'service_dpqu5ls',
      'template_0x6bde8',
      {
        username: `${formState.firstname} ${formState.name}`,
        subject: formState.subject,
        message: formState.message,
        email: formState.email
      },
      'user_NoqLvk0xZkWMUDfGurNvL'
    )
      .then(() => {
        message.success(intl.formatMessage({ id: 'email.success' }))
      })
      .catch(() => {
        message.error(intl.formatMessage({ id: 'email.error' }))
      })
  }

  return (
    <div className="contact-wrapper-form">
      <FormattedMessage id="contact.form.desc" />
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={onSubmit}
        style={{ marginTop: '10px' }}
      >
        <Form.Item
          label={intl.formatMessage({ id: 'contact.form.label.name' })}
          name="name"
          rules={[{ required: true, message: intl.formatMessage({ id: 'contact.form.required.name' }) }]}
        >
          <Input
            placeholder={intl.formatMessage({ id: 'contact.form.placeholder.name' })}
            name="name"
            onBlur={onChange}
            style={{ textTransform: 'uppercase' }}
          />
        </Form.Item>

        <Form.Item
          name="firstname"
          label={intl.formatMessage({ id: 'contact.form.label.firstname' })}
          rules={[{ required: true, message: intl.formatMessage({ id: 'contact.form.required.firstname' }) }]}
        >
          <Input
            placeholder={intl.formatMessage({ id: 'contact.form.placeholder.firstname' })}
            name="firstname"
            onBlur={onChange}
            style={{ textTransform: 'capitalize' }}
          />
        </Form.Item>

        <Form.Item
          name="email"
          label={intl.formatMessage({ id: 'contact.form.label.email' })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'contact.form.required.email' })
            },
            {
              type: 'email',
              message: intl.formatMessage({ id: 'contact.form.required.wrong_email' })
            }
          ]}
        >
          <Input
            onBlur={onChange}
            placeholder={intl.formatMessage({ id: 'contact.form.placeholder.email' })}
            name="email"
          />
        </Form.Item>

        <Form.Item
          name="subject"
          label={intl.formatMessage({ id: 'contact.form.label.subject' })}
          rules={[{ required: true, message: intl.formatMessage({ id: 'contact.form.required.subject' }) }]}
        >
          <Input
            onBlur={onChange}
            placeholder={intl.formatMessage({ id: 'contact.form.placeholder.subject' })}
            name="subject"
          />
        </Form.Item>

        <Form.Item
          name="message"
          label={intl.formatMessage({ id: 'contact.form.label.message' })}
          rules={[{ required: true, message: intl.formatMessage({ id: 'contact.form.required.message' }) }]}
        >
          <TextArea
            onBlur={onChange}
            placeholder={intl.formatMessage({ id: 'contact.form.placeholder.message' })}
            name="message"
            rows={6}
          />
        </Form.Item>

        <Form.Item>
          <Row align="center">
            <Col>
              <button type="submit" className="profile-contact-button">
                <FormattedMessage id="contact.form.label.send" />
              </button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </div>
  )
}

ContactForm.propTypes = {
  intl: PropTypes.shape().isRequired
}

export default injectIntl(ContactForm)
