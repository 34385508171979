import styled from 'styled-components'
import { Divider } from 'antd'

const AntDividerStyled = styled(Divider)`
  background: ${(props) => props.colors || 'white'};
  height: ${(props) => props.height || '2'}px;
  margin: ${(props) => (props.margin !== null ? props.margin : '24')}px 0;
  opacity: ${(props) => props.opacity || 1};
`

export default AntDividerStyled
