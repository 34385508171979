import React from 'react'
import PropTypes from 'prop-types'

import { colors } from 'assets/themes'
import { Row, Col } from 'lib'

const ImageGrid = ({ images, countFrom }) => {
  const renderCountOverlay = (more) => {
    const extra = images.length - (countFrom && countFrom > 5 ? 5 : countFrom)

    return [
      more && <div key="cover" className="cover" style={{ backgroundColor: colors.red }} />,
      more && (
        <div key="count-sub" className="cover-text" style={{ fontSize: '200%' }}>
          <p>+{extra}</p>
        </div>
      )
    ]
  }

  const renderOne = () => {
    const overlay = images.length > countFrom && countFrom === 1 ? renderCountOverlay(true) : null

    return (
      <Row>
        <Col span={24} className="height-one background" style={{ background: `url(${images[0]})` }}>
          {overlay}
        </Col>
      </Row>
    )
  }

  const renderTwo = () => {
    const overlay = images.length > countFrom && [2, 3].includes(+countFrom) ? renderCountOverlay(true) : null
    const conditionalRender =
      [3, 4].includes(images.length) || (images.length > +countFrom && [3, 4].includes(+countFrom))

    return (
      <Row>
        <Col
          span={12}
          className=" height-two background"
          style={{ background: `url(${conditionalRender ? images[1] : images[0]})` }}
        />
        <Col
          span={12}
          className=" height-two background"
          style={{ background: `url(${conditionalRender ? images[2] : images[1]})` }}
        >
          {overlay}
        </Col>
      </Row>
    )
  }

  const renderThree = () => {
    const conditionalRender = images.length === 4 || (images.length > +countFrom && +countFrom === 4)
    const overlay =
      !countFrom || countFrom > 5 || (images.length > countFrom && [4, 5].includes(+countFrom))
        ? renderCountOverlay(true)
        : null

    return (
      <Row>
        <Col
          span={8}
          className=" height-three background"
          style={{ background: `url(${conditionalRender ? images[1] : images[2]})` }}
        />
        <Col
          span={8}
          className=" height-three background"
          style={{ background: `url(${conditionalRender ? images[2] : images[3]})` }}
        />

        <Col
          span={8}
          className=" height-three background"
          style={{ background: `url(${conditionalRender ? images[3] : images[4]})` }}
        >
          {overlay}
        </Col>
      </Row>
    )
  }

  const imagesToShow = [...images]

  if (countFrom && images.length > countFrom) {
    imagesToShow.length = countFrom
  }

  return (
    <div className="images-grid">
      {[1, 3, 4].includes([...images].length) && renderOne()}
      {imagesToShow.length >= 2 && imagesToShow.length !== 4 && renderTwo()}
      {imagesToShow.length >= 4 && renderThree()}
    </div>
  )
}
ImageGrid.defaultProps = {
  countFrom: 5
}

ImageGrid.propTypes = {
  images: PropTypes.array.isRequired,
  countFrom: PropTypes.oneOf([1, 2, 3, 4, 5])
}

export default ImageGrid
