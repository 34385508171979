import React from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { Icon, ICONS_LIST, ICONS_MARGIN } from 'lib'
import lang from 'utils/intl/lang'
import { colors } from 'assets/themes'
import img from 'assets/images'
import history from 'app/history'

const AlbumCard = ({ intl, item, currentLang, ...props }) => {
  const { id, image, titleFr, titleEn } = item
  const { position } = props

  const handleClickAlbum = () => history.push(`/albums/${id}/gallery`)

  return (
    <div
      onClick={handleClickAlbum}
      aria-hidden="true"
      className={`album-element album-element-${position}`}
      style={{ backgroundImage: `url(${image ? image.fileUrl : img.noImage.url})` }}
    >
      <div className="album-element-color">
        {position === 'right' && (
          <div className="album-element-title album-element-title-right">
            <h3>
              {currentLang === lang.FR && titleFr}
              {currentLang === lang.GB && (titleEn || titleFr)}
            </h3>
            <Icon name={ICONS_LIST.plus} color={colors.white} />
          </div>
        )}

        {position === 'left' && (
          <div className="album-element-title album-element-title-left">
            <h3>
              {currentLang === lang.FR && titleFr}
              {currentLang === lang.GB && (titleEn || titleFr)}
            </h3>
            <Icon name={ICONS_LIST.plus} color={colors.white} margin={ICONS_MARGIN.right} />
          </div>
        )}
      </div>
    </div>
  )
}

AlbumCard.propTypes = {
  intl: PropTypes.shape().isRequired,
  currentLang: PropTypes.string.isRequired,
  item: PropTypes.shape().isRequired,
  position: PropTypes.oneOf(['left', 'right']).isRequired
}

export default injectIntl(AlbumCard)
