import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, ScrollAnimation } from 'lib'

const PageTitle = (props) => {
  const { title, position, theme, offsetAnimation } = props

  const className = theme === 'dark' ? 'page-title-dark' : 'page-title'
  return (
    <div className={`${className}-wrapper`}>
      <ScrollAnimation
        animateIn={`${className}-rop ${className}-rop-left`}
        duration={1}
        offset={offsetAnimation}
      />
      <ScrollAnimation
        animateIn={`${className}-rop ${className}-rop-center-left`}
        duration={1}
        offset={offsetAnimation}
      />
      <ScrollAnimation
        animateIn={`${className}-rop ${className}-rop-center-right`}
        duration={1}
        offset={offsetAnimation}
      />
      <ScrollAnimation
        animateIn={`${className}-rop ${className}-rop-right`}
        duration={1}
        offset={offsetAnimation}
      />

      <div className={`${className}-container`}>
        <Row justify={position} gutter={20}>
          <Col>
            <h1>{title}</h1>
          </Col>
        </Row>
      </div>
    </div>
  )
}

PageTitle.defaultProps = {
  title: null,
  position: 'center',
  theme: 'base',
  offsetAnimation: 150
}

PageTitle.propTypes = {
  title: PropTypes.node,
  position: PropTypes.string,
  theme: PropTypes.string,
  offsetAnimation: PropTypes.number
}

export default PageTitle
