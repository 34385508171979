import styled from 'styled-components'
import { Anchor } from 'antd'
import { colors } from 'assets/themes'

const AntAnchorStyled = styled(Anchor)`
  margin: 50px 0;
  .ant-anchor-ink {
    :before {
      background-color: ${colors.red};
    }
  }
`

export default AntAnchorStyled
