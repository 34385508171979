import React from 'react'
import PropTypes from 'prop-types'

import { Skeleton, Divider, Anchor } from 'lib'
import { colors } from 'assets/themes'

const { Link } = Anchor

const PrivacyPolicyFr = ({ contact, loading }) => {
  const { address, zipCode, city, email, phone } = contact

  return (
    <>
      <Anchor affix={false}>
        <Link href="#privacy-policy" title="I. Politique de confidentialité" />
        <Link href="#interpretation-definitions" title="II. Interprétation et définitions">
          <Link href="#interpretation" title="1. Interprétation" />
          <Link href="#definitions" title="2. Définitions" />
        </Link>
        <Link href="#collecting-using-data" title="III. Collecte et utilisation de vos données personnelles">
          <Link href="#data-type" title="1. Types de données collectées">
            <Link href="#personal-data" title="a. Données personnelles" />
            <Link href="#usage-data" title="b. Des données d'utilisation" />
            <Link href="#tracking-cookies" title="c. Technologies de suivi et cookies" />
          </Link>
          <Link href="#use-personal-data" title="2. Utilisation de vos données personnelles" />
          <Link href="#retention-personal-data" title="3. Conservation de vos données personnelles" />
          <Link href="#transfer-personal-data" title="4. Transfert de vos données personnelles" />
          <Link href="#disclosure-personal-data" title="5. Divulgation de vos données personnelles">
            <Link href="#business-transactions" title="a. Transactions commerciales" />
            <Link href="#law-enforcement" title="b. Forces de l'ordre" />
            <Link href="#other-requirements" title="c. Autres exigences légales" />
          </Link>
          <Link href="#security-personal-data" title="6. Sécurité de vos données personnelles" />
        </Link>
        <Link href="#childrens-privacy" title="IV. Confidentialité des enfants" />
        <Link href="#links-websites" title="V. Liens vers d'autres sites Web" />
        <Link
          href="#changes-privacy-policy"
          title="VI. Modifications de cette politique de confidentialité"
        />
        <Link href="#contact-us" title="VII. Nous contacter" />
      </Anchor>

      <Skeleton active round loading={loading} paragraph={{ rows: 3 }}>
        <h1 id="privacy-policy">I. Politique de confidentialité</h1>
        <Divider margin={6} colors={colors.red} />
        <p>Dernière mise à jour : 1er mars 2021</p>
        <p>
          Cette politique de confidentialité décrit nos politiques et procédures sur la collecte,
          l'utilisation et la divulgation de vos informations lorsque vous utilisez le service et vous informe
          de vos droits à la confidentialité et de la manière dont la loi vous protège.
        </p>
        <p>
          Nous utilisons vos données personnelles pour fournir et améliorer le service. En utilisant le
          Service, vous acceptez la collecte et l'utilisation d'informations conformément à la présente
          politique de confidentialité. Cette politique de confidentialité a été créée avec l'aide du{' '}
          <a
            href="https://www.privacypolicies.com/privacy-policy-generator/"
            rel="noreferrer"
            target="_blank"
          >
            générateur de politique de confidentialité
          </a>
          .
        </p>
      </Skeleton>

      <Skeleton active round loading={loading} paragraph={{ rows: 16 }}>
        <h1 id="interpretation-definitions">II. Interprétation et définitions</h1>
        <Divider margin={6} colors={colors.red} />
        <h2 id="interpretation">1. Interprétation</h2>
        <Divider margin={2} opacity={0.2} colors={colors.grey} />
        <p>
          Les mots dont la lettre initiale est en majuscule ont des significations définies dans les
          conditions suivantes. Les définitions suivantes ont la même signification, qu'elles apparaissent au
          singulier ou au pluriel.
        </p>

        <h2 id="definitions">2. Définitions</h2>
        <Divider margin={2} opacity={0.2} colors={colors.grey} />
        <p>Aux fins de cette politique de confidentialité :</p>
        <ul>
          <li>
            <p>
              <strong>Compte</strong> signifie un compte unique créé pour que vous puissiez accéder à notre
              service ou à des parties de notre service.
            </p>
          </li>
          <li>
            <p>
              <strong>La Société</strong> (dénommée «la Société», «Nous», «Notre» ou «Notre» dans le présent
              Contrat) fait référence à Piere Brézé, {address} {zipCode} {city}.
            </p>
          </li>
          <li>
            <p>
              <strong>Les cookies</strong> sont de petits fichiers placés sur votre ordinateur, appareil
              mobile ou tout autre appareil par un site Web, contenant les détails de votre historique de
              navigation sur ce site Web parmi ses nombreuses utilisations.
            </p>
          </li>
          <li>
            <p>
              <strong>Pays</strong> se réfère à : France
            </p>
          </li>
          <li>
            <p>
              <strong>Appareil</strong> désigne tout appareil pouvant accéder au Service tel qu'un ordinateur,
              un téléphone portable ou une tablette numérique.
            </p>
          </li>
          <li>
            <p>
              <strong>Les données personnelles</strong> sont toutes les informations relatives à une personne
              identifiée ou identifiable.
            </p>
          </li>
          <li>
            <p>
              <strong>Le service</strong> fait référence au site Web.
            </p>
          </li>
          <li>
            <p>
              <strong>Prestataire de services</strong> désigne toute personne physique ou morale qui traite
              les données pour le compte de la Société. Il se réfère à des sociétés tierces ou à des personnes
              employées par la Société pour faciliter le Service, pour fournir le Service au nom de la
              Société, pour exécuter des services liés au Service ou pour aider la Société à analyser la façon
              dont le Service est utilisé.
            </p>
          </li>
          <li>
            <p>
              <strong>Le service de médias sociaux tiers</strong> fait référence à tout site Web ou à tout
              site Web de réseau social via lequel un utilisateur peut se connecter ou créer un compte pour
              utiliser le service.
            </p>
          </li>
          <li>
            <p>
              <strong>Les données d'utilisation</strong> font référence aux données collectées
              automatiquement, soit générées par l'utilisation du service, soit à partir de l'infrastructure
              du service elle-même (par exemple, la durée d'une visite de page).
            </p>
          </li>
          <li>
            <p>
              <strong>Le site Web</strong> fait référence à l'Atelier Brézé - Luthier, accessible depuis{' '}
              <a href="https://atelierbreze.fr" rel="external nofollow noopener noreferrer" target="_blank">
                https://atelierbreze.fr
              </a>
            </p>
          </li>
          <li>
            <p>
              <strong>Vous</strong> désigne la personne qui accède ou utilise le Service, ou la société ou
              toute autre entité juridique au nom de laquelle cette personne accède ou utilise le Service,
              selon le cas.
            </p>
          </li>
        </ul>
      </Skeleton>

      <Skeleton active round loading={loading} paragraph={{ rows: 16 }}>
        <h1 id="collecting-using-data">III. Collecte et utilisation de vos données personnelles</h1>
        <Divider margin={6} colors={colors.red} />
        <h2 id="data-type">1. Types de données collectées</h2>
        <Divider margin={2} opacity={0.2} colors={colors.grey} />
        <h3 id="personal-data">a. Données personnelles</h3>
        <p>
          Lors de l'utilisation de notre service, nous pouvons vous demander de nous fournir certaines
          informations personnellement identifiables qui peuvent être utilisées pour vous contacter ou vous
          identifier. Les informations personnellement identifiables peuvent inclure, mais sans s'y limiter :
        </p>
        <ul>
          <li>
            <p>Adresse e-mail</p>
          </li>
          <li>
            <p>Prénom et nom</p>
          </li>
          <li>
            <p>Des données d'utilisation</p>
          </li>
        </ul>
        <h3 id="usage-data">b. Des données d'utilisation</h3>
        <p>Les données d'utilisation sont collectées automatiquement lors de l'utilisation du service.</p>
        <p>
          Les données d'utilisation peuvent inclure des informations telles que l'adresse de protocole
          Internet de votre appareil (par exemple, l'adresse IP), le type de navigateur, la version du
          navigateur, les pages de notre service que vous visitez, l'heure et la date de votre visite, le
          temps passé sur ces pages, l'appareil unique identifiants et autres données de diagnostic.
        </p>
        <p>
          Lorsque vous accédez au service par ou via un appareil mobile, nous pouvons collecter certaines
          informations automatiquement, y compris, mais sans s'y limiter, le type d'appareil mobile que vous
          utilisez, l'identifiant unique de votre appareil mobile, l'adresse IP de votre appareil mobile,
          votre mobile système d'exploitation, le type de navigateur Internet mobile que vous utilisez, les
          identifiants uniques de l'appareil et d'autres données de diagnostic.
        </p>
        <p>
          Nous pouvons également collecter des informations que votre navigateur envoie chaque fois que vous
          visitez notre service ou lorsque vous accédez au service par ou via un appareil mobile.
        </p>
        <h3 id="tracking-cookies">c. Technologies de suivi et cookies</h3>
        <p>
          Nous utilisons des cookies et des technologies de suivi similaires pour suivre l'activité sur notre
          service et stocker certaines informations. Les technologies de suivi utilisées sont des balises, des
          balises et des scripts pour collecter et suivre des informations et pour améliorer et analyser notre
          service. Les technologies que nous utilisons peuvent inclure :
        </p>
        <ul>
          <li>
            <strong>Cookies ou cookies de navigateur.</strong> Un cookie est un petit fichier placé sur votre
            appareil. Vous pouvez demander à votre navigateur de refuser tous les cookies ou d'indiquer quand
            un cookie est envoyé. Cependant, si vous n'acceptez pas les cookies, il se peut que vous ne
            puissiez pas utiliser certaines parties de notre service. Sauf si vous avez ajusté les paramètres
            de votre navigateur afin qu'il refuse les cookies, notre service peut utiliser des cookies.
          </li>
          <li>
            <strong>Cookies Flash.</strong> Certaines fonctionnalités de notre service peuvent utiliser des
            objets stockés localement (ou des cookies Flash) pour collecter et stocker des informations sur
            vos préférences ou votre activité sur notre service. Les cookies Flash ne sont pas gérés par les
            mêmes paramètres de navigateur que ceux utilisés pour les cookies de navigateur. Pour plus
            d'informations sur la manière de supprimer les cookies Flash, veuillez lire "Où puis-je modifier
            les paramètres de désactivation ou de suppression des objets partagés locaux?" disponible à l'
            adresse{' '}
            <a
              href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
            </a>
          </li>
          <li>
            <strong>Balises Web.</strong> Certaines sections de notre Service et de nos e-mails peuvent
            contenir de petits fichiers électroniques appelés balises Web (également appelés gifs clairs,
            pixels invisibles et gifs à pixel unique) qui permettent à la Société, par exemple, de compter les
            utilisateurs qui ont visité ces pages. ou a ouvert un e-mail et pour d'autres statistiques de site
            Web connexes (par exemple, enregistrer la popularité d'une certaine section et vérifier
            l'intégrité du système et du serveur).
          </li>
        </ul>
        <p>
          Les cookies peuvent être des cookies «persistants» ou «de session». Les cookies persistants restent
          sur votre ordinateur personnel ou appareil mobile lorsque vous vous déconnectez, tandis que les
          cookies de session sont supprimés dès que vous fermez votre navigateur Web. En savoir plus sur les
          cookies :{' '}
          <a href="https://www.privacypolicies.com/blog/cookies/" rel="noreferrer" target="_blank">
            que sont les cookies ?
          </a>
        </p>
        <p>
          Nous utilisons à la fois des cookies de session et des cookies persistants aux fins décrites
          ci-dessous :
        </p>
        <ul>
          <li>
            <p>
              <strong>Cookies nécessaires / essentiels</strong>
            </p>
            <p>Type : Cookies de session</p>
            <p>Administré par : nous</p>
            <p>
              Objectif : Ces cookies sont essentiels pour vous fournir les services disponibles sur le site
              Web et pour vous permettre d'utiliser certaines de ses fonctionnalités. Ils aident à
              authentifier les utilisateurs et à empêcher l'utilisation frauduleuse des comptes
              d'utilisateurs. Sans ces cookies, les services que vous avez demandés ne peuvent pas être
              fournis et nous n'utilisons ces cookies que pour vous fournir ces services.
            </p>
          </li>
          <li>
            <p>
              <strong>Politique de cookies / Avis d'acceptation des cookies</strong>
            </p>
            <p>Type : Cookies persistants</p>
            <p>Administré par : nous</p>
            <p>
              Finalité : Ces cookies identifient si les utilisateurs ont accepté l'utilisation de cookies sur
              le site Web.
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies de fonctionnalité</strong>
            </p>
            <p>Type : Cookies persistants</p>
            <p>Administré par : nous</p>
            <p>
              Objectif : ces cookies nous permettent de nous souvenir des choix que vous faites lorsque vous
              utilisez le site Web, tels que la mémorisation de vos informations de connexion ou de votre
              préférence de langue. Le but de ces cookies est de vous offrir une expérience plus personnelle
              et de vous éviter d'avoir à ressaisir vos préférences chaque fois que vous utilisez le site Web.
            </p>
          </li>
        </ul>
        <p>
          Pour plus d'informations sur les cookies que nous utilisons et vos choix en matière de cookies,
          veuillez visiter notre Politique de cookies ou la section Cookies de notre Politique de
          confidentialité.
        </p>
        <h2 id="use-personal-data">2. Utilisation de vos données personnelles</h2>
        <Divider margin={2} opacity={0.2} colors={colors.grey} />
        <p>La Société peut utiliser les données personnelles aux fins suivantes :</p>
        <ul>
          <li>
            <p>
              <strong>Pour fournir et maintenir notre service</strong>, y compris pour surveiller
              l'utilisation de notre service.
            </p>
          </li>
          <li>
            <p>
              <strong>Pour gérer votre compte :</strong> pour gérer votre inscription en tant qu'utilisateur
              du service. Les données personnelles que vous fournissez peuvent vous donner accès à différentes
              fonctionnalités du service qui vous sont disponibles en tant qu'utilisateur enregistré.
            </p>
          </li>
          <li>
            <p>
              <strong>Pour l'exécution d'un contrat :</strong> le développement, la conformité et l'engagement
              du contrat d'achat pour les produits, articles ou services que vous avez achetés ou de tout
              autre contrat avec nous via le service.
            </p>
          </li>
          <li>
            <p>
              <strong>Pour vous contacter :</strong> Pour vous contacter par e-mail, appels téléphoniques, SMS
              ou autres formes équivalentes de communication électronique, telles que les notifications push
              d'une application mobile concernant les mises à jour ou les communications informatives liées
              aux fonctionnalités, produits ou services sous contrat, y compris les mises à jour de sécurité,
              lorsque cela est nécessaire ou raisonnable pour leur mise en œuvre.
            </p>
          </li>
          <li>
            <p>
              <strong>Pour vous fournir</strong> des nouvelles, des offres spéciales et des informations
              générales sur d'autres biens, services et événements que nous proposons et similaires à ceux que
              vous avez déjà achetés ou sur lesquels vous vous êtes renseigné, à moins que vous n'ayez choisi
              de ne pas recevoir ces informations.
            </p>
          </li>
          <li>
            <p>
              <strong>Pour gérer vos demandes :</strong> pour assister et gérer vos demandes à nous.
            </p>
          </li>
          <li>
            <p>
              <strong>Pour les transferts d'entreprise :</strong> Nous pouvons utiliser vos informations pour
              évaluer ou mener une fusion, un désinvestissement, une restructuration, une réorganisation, une
              dissolution ou toute autre vente ou transfert de tout ou partie de nos actifs, que ce soit dans
              le cadre d'une exploitation en cours ou dans le cadre d'une faillite, liquidation, ou une
              procédure similaire, dans laquelle les données personnelles que nous détenons sur les
              utilisateurs de nos services font partie des actifs transférés.
            </p>
          </li>
          <li>
            <p>
              <strong>À d'autres fins</strong> : Nous pouvons utiliser vos informations à d'autres fins,
              telles que l'analyse des données, l'identification des tendances d'utilisation, la détermination
              de l'efficacité de nos campagnes promotionnelles et pour évaluer et améliorer notre service, nos
              produits, nos services, notre marketing et votre expérience.
            </p>
          </li>
        </ul>
        <p>Nous pouvons partager vos informations personnelles dans les situations suivantes :</p>
        <ul>
          <li>
            <strong>Avec les fournisseurs de services :</strong> nous pouvons partager vos informations
            personnelles avec les fournisseurs de services pour surveiller et analyser l'utilisation de notre
            service, pour vous contacter.
          </li>
          <li>
            <strong>Pour les transferts d'entreprise :</strong> Nous pouvons partager ou transférer vos
            informations personnelles dans le cadre ou pendant les négociations de toute fusion, vente
            d'actifs de la société, financement ou acquisition de tout ou partie de nos activités à une autre
            société.
          </li>
          <li>
            <strong>Avec les affiliés :</strong> nous pouvons partager vos informations avec nos affiliés,
            auquel cas nous demanderons à ces affiliés d'honorer cette politique de confidentialité. Les
            affiliés comprennent notre société mère et toutes autres filiales, partenaires de coentreprise ou
            autres sociétés que nous contrôlons ou qui sont sous contrôle commun avec nous.
          </li>
          <li>
            <strong>Avec des partenaires commerciaux :</strong> Nous pouvons partager vos informations avec
            nos partenaires commerciaux pour vous proposer certains produits, services ou promotions.
          </li>
          <li>
            <strong>Avec d'autres utilisateurs :</strong> lorsque vous partagez des informations personnelles
            ou interagissez d'une autre manière dans les zones publiques avec d'autres utilisateurs, ces
            informations peuvent être vues par tous les utilisateurs et peuvent être diffusées publiquement à
            l'extérieur. Si vous interagissez avec d'autres utilisateurs ou que vous vous inscrivez via un
            service de médias sociaux tiers, vos contacts sur le service de médias sociaux tiers peuvent voir
            votre nom, votre profil, vos photos et la description de votre activité. De même, les autres
            utilisateurs pourront afficher les descriptions de votre activité, communiquer avec vous et
            afficher votre profil.
          </li>
          <li>
            <strong>Avec votre consentement</strong> : nous pouvons divulguer vos informations personnelles à
            toute autre fin avec votre consentement.
          </li>
        </ul>
        <h2 id="retention-personal-data">3. Conservation de vos données personnelles</h2>
        <Divider margin={2} opacity={0.2} colors={colors.grey} />
        <p>
          La Société ne conservera vos données personnelles que le temps nécessaire aux fins énoncées dans la
          présente politique de confidentialité. Nous conserverons et utiliserons vos données personnelles
          dans la mesure nécessaire pour nous conformer à nos obligations légales (par exemple, si nous sommes
          tenus de conserver vos données pour nous conformer aux lois applicables), résoudre les litiges et
          appliquer nos accords et politiques juridiques.
        </p>
        <p>
          La Société conservera également les données d'utilisation à des fins d'analyse interne. Les données
          d'utilisation sont généralement conservées pendant une période plus courte, sauf lorsque ces données
          sont utilisées pour renforcer la sécurité ou pour améliorer la fonctionnalité de notre service, ou
          nous sommes légalement obligés de conserver ces données pendant des périodes plus longues.
        </p>
        <h2 id="transfer-personal-data">4. Transfert de vos données personnelles</h2>
        <Divider margin={2} opacity={0.2} colors={colors.grey} />
        <p>
          Vos informations, y compris les données personnelles, sont traitées dans les bureaux d'exploitation
          de la société et dans tout autre endroit où se trouvent les parties impliquées dans le traitement.
          Cela signifie que ces informations peuvent être transférées et conservées sur des ordinateurs situés
          en dehors de votre état, province, pays ou autre juridiction gouvernementale où les lois sur la
          protection des données peuvent différer de celles de votre juridiction.
        </p>
        <p>
          Votre consentement à cette politique de confidentialité suivi de la soumission de ces informations
          représente votre accord à ce transfert.
        </p>
        <p>
          La Société prendra toutes les mesures raisonnablement nécessaires pour garantir que vos données sont
          traitées en toute sécurité et conformément à la présente politique de confidentialité et aucun
          transfert de vos données personnelles n'aura lieu à une organisation ou à un pays à moins que des
          contrôles adéquats ne soient en place, y compris la sécurité de Vos données et autres informations
          personnelles.
        </p>
        <h2 id="disclosure-personal-data">5. Divulgation de vos données personnelles</h2>
        <Divider margin={2} opacity={0.2} colors={colors.grey} />
        <h3 id="business-transactions">a. Transactions commerciales</h3>
        <p>
          Si la société est impliquée dans une fusion, une acquisition ou une vente d'actifs, vos données
          personnelles peuvent être transférées. Nous vous informerons avant que vos données personnelles ne
          soient transférées et deviennent soumises à une politique de confidentialité différente.
        </p>
        <h3 id="law-enforcement">b. Forces de l'ordre</h3>
        <p>
          Dans certaines circonstances, la Société peut être tenue de divulguer vos données personnelles si la
          loi l'exige ou en réponse à des demandes valides des autorités publiques (par exemple un tribunal ou
          une agence gouvernementale).
        </p>
        <h3 id="other-requirements">c. Autres exigences légales</h3>
        <p>
          La Société peut divulguer vos données personnelles en croyant de bonne foi qu'une telle action est
          nécessaire pour :
        </p>
        <ul>
          <li>Se conformer à une obligation légale</li>
          <li>Protéger et défendre les droits ou la propriété de l'entreprise</li>
          <li>Prévenir ou enquêter sur d'éventuels actes répréhensibles en relation avec le Service</li>
          <li>Protéger la sécurité personnelle des Utilisateurs du Service ou du public</li>
          <li>Protéger contre la responsabilité légale</li>
        </ul>
        <h2 id="security-personal-data">6. Sécurité de vos données personnelles</h2>
        <Divider margin={2} opacity={0.2} colors={colors.grey} />
        <p>
          La sécurité de vos données personnelles est importante pour nous, mais rappelez-vous qu'aucune
          méthode de transmission sur Internet ou méthode de stockage électronique n'est sécurisée à 100%.
          Bien que nous nous efforcions d'utiliser des moyens commercialement acceptables pour protéger vos
          données personnelles, nous ne pouvons garantir leur sécurité absolue.
        </p>
      </Skeleton>

      <Skeleton active round loading={loading} paragraph={{ rows: 2 }}>
        <h1 id="childrens-privacy">IV. Confidentialité des enfants</h1>
        <Divider margin={6} colors={colors.red} />
        <p>
          Notre service ne s'adresse à personne de moins de 13 ans. Nous ne collectons pas sciemment
          d'informations personnellement identifiables de toute personne de moins de 13 ans. Si vous êtes un
          parent ou un tuteur et que vous savez que votre enfant nous a fourni des données personnelles,
          veuillez Nous contacter. Si nous apprenons que nous avons collecté des données personnelles de toute
          personne de moins de 13 ans sans vérification du consentement parental, nous prenons des mesures
          pour supprimer ces informations de nos serveurs.
        </p>
        <p>
          Si nous devons nous fier au consentement comme base légale pour le traitement de vos informations et
          que votre pays requiert le consentement d'un parent, nous pouvons exiger le consentement de vos
          parents avant de collecter et d'utiliser ces informations.
        </p>
      </Skeleton>

      <Skeleton active round loading={loading} paragraph={{ rows: 2 }}>
        <h1 id="links-websites">V. Liens vers d'autres sites Web</h1>
        <Divider margin={6} colors={colors.red} />
        <p>
          Notre service peut contenir des liens vers d'autres sites Web qui ne sont pas exploités par nous. Si
          vous cliquez sur un lien tiers, vous serez dirigé vers le site de ce tiers. Nous vous conseillons
          vivement de consulter la politique de confidentialité de chaque site que vous visitez.
        </p>
        <p>
          Nous n'avons aucun contrôle et n'assumons aucune responsabilité pour le contenu, les politiques de
          confidentialité ou les pratiques de tout site ou service tiers.
        </p>
      </Skeleton>

      <Skeleton active round loading={loading} paragraph={{ rows: 3 }}>
        <h1 id="changes-privacy-policy">VI. Modifications de cette politique de confidentialité</h1>
        <Divider margin={6} colors={colors.red} />
        <p>
          Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Nous vous
          informerons de tout changement en publiant la nouvelle politique de confidentialité sur cette page.
        </p>
        <p>
          Nous vous informerons par e-mail et / ou par un avis bien visible sur notre service, avant que le
          changement ne devienne effectif, et nous mettrons à jour la date de «dernière mise à jour» en haut
          de cette politique de confidentialité.
        </p>
        <p>
          Il vous est conseillé de consulter périodiquement cette politique de confidentialité pour tout
          changement. Les modifications apportées à cette politique de confidentialité sont effectives
          lorsqu'elles sont publiées sur cette page.
        </p>
      </Skeleton>

      <Skeleton active round loading={loading} paragraph={{ rows: 4 }}>
        <h1 id="contact-us">VII. Nous contacter</h1>
        <Divider margin={6} colors={colors.red} />
        <p>Si vous avez des questions sur cette politique de confidentialité, vous pouvez nous contacter :</p>
        <ul>
          <li>
            <p>Par email : {email}</p>
          </li>
          <li>
            <p>
              En visitant cette page sur notre site internet :{' '}
              <a href="https://atelierbreze.fr" rel="external nofollow noopener noreferrer" target="_blank">
                https://atelierbreze.fr
              </a>
            </p>
          </li>
          {phone && (
            <li>
              <p>Par téléphone : {phone.split('').map((x, i) => (i % 2 === 0 ? ` ${x}` : x))}</p>
            </li>
          )}
        </ul>
      </Skeleton>
    </>
  )
}

PrivacyPolicyFr.propTypes = {
  contact: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired
}

export default PrivacyPolicyFr
