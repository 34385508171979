import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

import { Row, Col, Layout, PageTitle, Divider } from 'lib'
import img from 'assets/images'
import { colors } from 'assets/themes'

import ServiceItem from './_components/ServiceItem'

const Services = ({ intl }) => {
  return (
    <Layout id="services">
      <PageTitle
        title={intl.formatMessage({
          id: 'menu.services'
        })}
        theme="dark"
      />
      <Row align="start">
        <Col span={24}>
          <Row align="middle" justify="space-between">
            <Col span={24}>
              <ServiceItem
                imgUrl={img.maintenance}
                title={intl.formatMessage({ id: 'services.maintenance.title' })}
                descriptions={[
                  intl.formatMessage({ id: 'services.maintenance.changing_strings' }),
                  intl.formatMessage({ id: 'services.maintenance.adjustment_maintenance_guitars' }),
                  intl.formatMessage({ id: 'services.maintenance.change_mechanics' })
                ]}
                position="right"
              />
            </Col>
            <Divider colors={colors.red} height={4} margin={12} />
            <Col span={24}>
              <ServiceItem
                imgUrl={img.elec}
                title={intl.formatMessage({ id: 'services.electronics.title' })}
                descriptions={[
                  intl.formatMessage({ id: 'services.electronics.changing_microphones' }),
                  intl.formatMessage({ id: 'services.electronics.changing_components' }),
                  intl.formatMessage({ id: 'services.electronics.electronic_refurbishment' }),
                  intl.formatMessage({ id: 'services.electronics.copper_shielding' })
                ]}
                position="left"
              />
            </Col>
            <Divider colors={colors.red} height={4} margin={12} />
            <Col span={24}>
              <ServiceItem
                imgUrl={img.wood}
                title={intl.formatMessage({ id: 'services.wood.title' })}
                descriptions={[
                  intl.formatMessage({ id: 'services.wood.easel_gluing' }),
                  intl.formatMessage({ id: 'services.wood.head_fracture_gluing' })
                ]}
                position="right"
              />
            </Col>
            <Divider colors={colors.red} height={4} margin={12} />
            <Col span={24}>
              <ServiceItem
                imgUrl={img.fabrication}
                title={intl.formatMessage({ id: 'services.production.title' })}
                descriptions={[
                  intl.formatMessage({ id: 'services.production.guitars_basses' }),
                  intl.formatMessage({ id: 'services.production.quote' })
                ]}
                position="left"
              />
            </Col>
            <Divider colors={colors.red} height={4} margin={12} />
            <Col span={24}>
              <ServiceItem
                imgUrl={img.drive}
                title={intl.formatMessage({ id: 'services.drive.title' })}
                descriptions={[
                  intl.formatMessage({ id: 'services.drive.delivery' }),
                  intl.formatMessage({ id: 'services.drive.taking_off_instrument' })
                ]}
                position="right"
              />
            </Col>
            <Divider colors={colors.red} height={4} margin={12} />
            <Col span={24}>
              <ServiceItem
                imgUrl={img.other}
                title={intl.formatMessage({ id: 'services.others.title' })}
                descriptions={[
                  intl.formatMessage({ id: 'services.others.planimetry_frets' }),
                  intl.formatMessage({ id: 'services.others.refrettage' }),
                  intl.formatMessage({ id: 'services.others.saddle_easel' })
                ]}
                position="left"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="service-desc">
        <FormattedMessage id="services.desc" />
      </div>
    </Layout>
  )
}

Services.propTypes = {
  intl: PropTypes.shape().isRequired
}

export default injectIntl(Services)
