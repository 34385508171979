import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Footer, ReducedMenu } from 'lib'

import { fetchContact } from 'store/actions'

import TermsConditionsOfUse from './modules/terms-conditions-of-use'

const TermsConditionsOfUsePage = ({ actions }) => {
  useEffect(() => {
    actions.initData()
  }, [])

  return (
    <>
      <ReducedMenu />
      <TermsConditionsOfUse />
      <Footer showLinks={false} />
    </>
  )
}

TermsConditionsOfUsePage.propTypes = {
  actions: PropTypes.shape().isRequired
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    initData: () => {
      Promise.all([dispatch(fetchContact())])
    }
  }
})

export default connect(null, mapDispatchToProps)(TermsConditionsOfUsePage)
