import React from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { Row, Col, Icon, ICONS_MARGIN } from 'lib'

const NetworkLink = (props) => {
  const { link, icon, name } = props

  return (
    <Row className="contact-wrapper-infos-item" justify="center" gutter={[0, 24]}>
      <Col span={16}>
        <a className="contact-link" target="_blank" rel="noreferrer" href={link}>
          <Row justify="center" align="start">
            <Icon name={icon} margin={ICONS_MARGIN.right} />@{name}
          </Row>
        </a>
      </Col>
    </Row>
  )
}

NetworkLink.propTypes = {
  icon: PropTypes.shape().isRequired,
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

export default injectIntl(NetworkLink)
