import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Row, Col, Spin, PageTitle, BackTop, ReducedMenu, Footer } from 'lib'
import { colors } from 'assets/themes'
import loadingSelector from 'utils/redux/selectors'
import { useWindowWidth, isSizeBetween, BREAKPOINTS } from 'utils/tools/page'

import { fetchAlbums, fetchContact } from 'store/actions'
import { FETCH_ALBUMS } from 'store/constants'

import AlbumCard from './_components/AlbumCard'

const Albums = ({ intl, actions, albums, loading, ...props }) => {
  useEffect(() => {
    actions.initData()
  }, [])

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(isSizeBetween(0, BREAKPOINTS.MD))
  }, [useWindowWidth()])

  return (
    <>
      <ReducedMenu />
      <div className="albums-wrapper">
        <BackTop />
        <div>
          <PageTitle
            title={intl.formatMessage({
              id: 'menu.albums'
            })}
            offsetAnimation={0}
          />
        </div>
        {loading ? (
          <Col span={24}>
            <Row justify="center">
              <Spin color={colors.red} size="large" margin />
            </Row>
          </Col>
        ) : (
          albums.items.map((item, index) => (
            <Row
              justify={index % 2 === 0 ? 'start' : 'end'}
              style={{ marginTop: index > 0 && !isMobile ? '-100px' : 0 }}
              gutter={[6, 24]}
              key={index}
            >
              <Col lg={12} xs={24}>
                <AlbumCard
                  item={item}
                  position={index % 2 === 0 ? 'left' : 'right'}
                  currentLang={props.currentLang}
                />
              </Col>
            </Row>
          ))
        )}
      </div>
      <Footer showLinks={false} />
    </>
  )
}

Albums.propTypes = {
  intl: PropTypes.shape().isRequired,
  actions: PropTypes.shape().isRequired,
  albums: PropTypes.shape().isRequired,
  currentLang: PropTypes.string,
  loading: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  albums: state.albums,
  currentLang: state.language,
  loading: loadingSelector([FETCH_ALBUMS])(state)
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    initData: () => {
      Promise.all([dispatch(fetchAlbums()), dispatch(fetchContact())])
    }
  }
})

const withIntl = injectIntl(Albums)
export default connect(mapStateToProps, mapDispatchToProps)(withIntl)
