import lang from 'utils/intl/lang'
import { Success } from 'utils/redux/actionPattern'
import { message } from 'lib'

import * as constants from './constants'

export const language = (state = lang.FR, action) => {
  switch (action.type) {
    case constants.CHANGE_LANGUAGE:
      return action.payload
    default:
      return state
  }
}

export const loadingReducer = (state = {}, action) => {
  const { type } = action
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type)
  if (!matches) return state

  const [, requestName, requestState] = matches
  return {
    ...state,
    [requestName]: requestState === 'REQUEST'
  }
}

export const errorReducer = (state = {}, action) => {
  const { error, type, payload } = action
  const matches = /(.*)_(REQUEST|FAILURE)/.exec(type)
  if (!matches) return state
  const [, , requestState] = matches

  if (error) {
    message.error('Une erreur est survenue')
  }

  return requestState === 'FAILURE' ? payload : ''
}

export const contact = (state = {}, action) => {
  switch (action.type) {
    case Success(constants.FETCH_CONTACT):
      return {
        ...state,
        ...action.payload.contact
      }
    default:
      return state
  }
}

export const gallery = (state = { items: [], total: null }, action) => {
  switch (action.type) {
    case Success(constants.FETCH_GALLERY):
      return {
        ...state,
        items: action.payload.items,
        total: action.payload.total
      }
    default:
      return state
  }
}

export const albums = (state = { items: [], total: null }, action) => {
  switch (action.type) {
    case Success(constants.FETCH_ALBUMS):
      return {
        ...state,
        items: action.payload.items,
        total: action.payload.total
      }
    default:
      return state
  }
}

export const albumReducer = (state = {}, action) => {
  switch (action.type) {
    case Success(constants.FETCH_ALBUM):
      return {
        ...state,
        ...action.payload.album
      }
    default:
      return state
  }
}

export const publications = (state = { items: [], total: null }, action) => {
  switch (action.type) {
    case Success(constants.FETCH_PUBLICATIONS):
      return {
        ...state,
        items: action.payload.items,
        total: action.payload.total
      }
    default:
      return state
  }
}
