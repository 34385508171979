import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedDate } from 'react-intl'
import { connect } from 'react-redux'
import _ from 'lodash'
import ReactHtmlParser from 'react-html-parser'

import { Typography, ImageGrid, Row, Col, Modal, Icon, ImageCarousel, ICONS_LIST, Divider } from 'lib'
import img from 'assets/images'
import { colors } from 'assets/themes'

import lang from 'utils/intl/lang'

const { Paragraph } = Typography

const GalleryCard = (props) => {
  const [isShownModal, setShowModal] = useState(false)
  const showModal = useCallback(() => {
    setShowModal(true)
  }, [])
  const hideModal = useCallback(() => {
    setShowModal(false)
  }, [])

  const { element, locale } = props

  return (
    <>
      <div key={element.id} className="gallery-card" onClick={showModal} aria-hidden="true">
        <ImageGrid
          images={
            element.images.length
              ? [..._.sortBy(element.images, ['id']).map((image) => image.fileUrl)]
              : [img.noImage.url]
          }
        />
        <div className="gallery-card-text-wrapper">
          <Paragraph ellipsis={{ expandable: false, rows: 5 }}>
            {ReactHtmlParser(
              locale === lang.FR ? element.descriptionFr : element.descriptionEn || element.descriptionFr
            )}
          </Paragraph>
          <Row align="end" gutter={24}>
            <Col>
              <Icon name={ICONS_LIST.plusFilled} color={colors.red} />
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        destroyOnClose
        onCancel={hideModal}
        footer={null}
        visible={isShownModal}
        className="image-modal"
        width={1250}
      >
        <Row>
          <Col sm={16} xs={24}>
            {element.images.length > 0 ? (
              <>
                <ImageCarousel
                  autoPlay
                  showPlayButton={false}
                  items={_.sortBy(element.images, ['id']).map((image) => ({
                    fullscreen: `${image.fileUrl}`,
                    original: `${image.fileUrl}?width=600`,
                    thumbnail: `${image.fileUrl}?width=100`
                  }))}
                  showThumbnails={false}
                  showFullscreenButton={false}
                  showBullets
                />
              </>
            ) : (
              <>
                <ImageCarousel
                  autoPlay
                  showPlayButton={false}
                  items={[
                    {
                      fullscreen: `${img.noImage.url}`,
                      original: `${img.noImage.url}`,
                      thumbnail: `${img.noImage.url}`
                    }
                  ]}
                  showThumbnails={false}
                  showFullscreenButton={false}
                  showBullets
                />
              </>
            )}
          </Col>
          <Col sm={8} xs={24} className="description-wrapper">
            <Row>
              <Col span={24} className="time-wrapper">
                <FormattedDate
                  value={element.createdAt}
                  weekday="short"
                  day="numeric"
                  month="long"
                  year="numeric"
                  hour="2-digit"
                />
              </Col>
              <Divider margin="8" />
              <Col>
                {ReactHtmlParser(
                  locale === lang.FR ? element.descriptionFr : element.descriptionEn || element.descriptionFr
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

GalleryCard.propTypes = {
  element: PropTypes.shape().isRequired,
  locale: PropTypes.string.isRequired
}

const mapStateToProps = (state) => ({
  locale: state.language
})

export default connect(mapStateToProps)(injectIntl(GalleryCard))
