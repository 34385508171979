import axios from 'axios'
import { request, success, failure } from './requestStatus'

const getUrl = (url) => {
  if (window.location.hostname.indexOf('localhost') !== -1) {
    return `https://127.0.0.1:8000${url}`
  }
  return `https://api.atelierbreze.fr${url}`
}

export const METHOD = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete'
}

const requestWrapper = (method, url, type, params, context) => async (dispatch) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }

  const action =
    method === METHOD.GET
      ? {
          url: getUrl(url),
          method,
          params,
          headers
        }
      : {
          url: getUrl(url),
          method,
          data: params,
          headers
        }

  dispatch({ type: `${type}_${request}` })
  await axios(action)
    .then((res) => {
      dispatch({
        payload: res.data,
        type: `${type}_${success}`,
        context
      })
    })
    .catch((error) => {
      dispatch({
        payload: error,
        type: `${type}_${failure}`,
        error: true
      })
    })
}

export default requestWrapper
