import React from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { BackTop } from 'lib'
import lang from 'utils/intl/lang'
import loadingSelector from 'utils/redux/selectors'

import { FETCH_CONTACT } from 'store/constants'

import PrivacyPolicyEn from './en'
import PrivacyPolicyFr from './fr'

const PrivacyPolicy = ({ currentLang, ...props }) => {
  return (
    <div className="privacy-policy">
      <BackTop />

      {currentLang === lang.GB && <PrivacyPolicyEn {...props} />}
      {currentLang === lang.FR && <PrivacyPolicyFr {...props} />}
    </div>
  )
}

PrivacyPolicy.propTypes = {
  currentLang: PropTypes.string
}

const mapStateToProps = (state) => ({
  currentLang: state.language,
  contact: state.contact,
  loading: loadingSelector([FETCH_CONTACT])(state)
})

const withIntl = injectIntl(PrivacyPolicy)
export default connect(mapStateToProps)(withIntl)
