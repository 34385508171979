import React, { useEffect, useState } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { useWindowWidth, isSizeBetween, BREAKPOINTS } from 'utils/tools/page'
import loadingSelector from 'utils/redux/selectors'
import { Row, Col, Layout, PageTitle, Svg, ICONS_LIST, Spin } from 'lib'
import svg from 'assets/svg'
import { colors } from 'assets/themes'
import { FETCH_CONTACT } from 'store/constants'

import NetworkLink from './_components/NetworkLink'
import ContactForm from './_components/ContactForm'

const SVGWrapper = styled.div`
  width: 100%;
  padding: 10px;
`
const SVG = styled(Svg)`
  fill: ${colors.white};
`

const Contact = ({ intl, contact, loading }) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(isSizeBetween(0, BREAKPOINTS.SM))
  }, [useWindowWidth()])

  const { address, zipCode, city, email, phone, facebookUrl, instagramUrl } = contact

  return (
    <Layout id="contact">
      <PageTitle
        title={intl.formatMessage({
          id: 'menu.contact'
        })}
      />
      {isMobile ? (
        <div className="contact">
          <Col span={24}>
            <div className="contact-wrapper-infos">
              <Col>
                <div className="contact-wrapper-infos-details">
                  <div className="contact-wrapper-infos-title">
                    <Row justify="center">
                      <Col lg={6} sm={12} xs={12}>
                        <SVGWrapper>
                          <SVG svg={svg.location} />
                        </SVGWrapper>
                      </Col>
                    </Row>
                    <Row justify="center">
                      <h4>
                        <FormattedMessage id="contact.details.where_find" />
                      </h4>
                    </Row>
                  </div>
                  <Row className="contact-wrapper-infos-item" justify="center" gutter={[0, 24]}>
                    {loading ? (
                      <Col span={24}>
                        <Spin color={colors.grey} />
                      </Col>
                    ) : (
                      <>
                        <Col span={24}>
                          {address} <br />
                          {zipCode} {city}
                        </Col>
                        <Col span={24}>{email}</Col>
                        <Col span={24}>
                          {phone && phone.split('').map((x, i) => (i % 2 === 0 ? ` ${x}` : x))}
                        </Col>
                        <Col span={24}>
                          <FormattedMessage id="contact.details.only_appointment" />
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
              </Col>
              <Col>
                <div className="contact-wrapper-infos-network">
                  <div className="contact-wrapper-infos-title">
                    <Row justify="center">
                      <Col lg={6} sm={12} xs={12}>
                        <SVGWrapper>
                          <SVG svg={svg.network} />
                        </SVGWrapper>
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col>
                        <h4>
                          <FormattedMessage id="contact.network" />
                        </h4>
                      </Col>
                    </Row>
                  </div>
                  {loading ? (
                    <Row justify="center">
                      <Spin color={colors.red} />
                    </Row>
                  ) : (
                    <>
                      {facebookUrl && (
                        <NetworkLink
                          link={facebookUrl}
                          icon={ICONS_LIST.facebook}
                          name={facebookUrl
                            .split('/')
                            .filter((x) => x)
                            .pop()}
                        />
                      )}
                      {instagramUrl && (
                        <NetworkLink
                          link={instagramUrl}
                          icon={ICONS_LIST.instagram}
                          name={instagramUrl
                            .split('/')
                            .filter((x) => x)
                            .pop()}
                        />
                      )}
                    </>
                  )}
                </div>
              </Col>
            </div>
          </Col>
          <Col span={24}>
            <ContactForm />
          </Col>
        </div>
      ) : (
        <div className="contact">
          <Col span={10}>
            <div className="contact-wrapper-infos">
              <Col>
                <div className="contact-wrapper-infos-details">
                  <div className="contact-wrapper-infos-title">
                    <Row justify="center">
                      <Col lg={6} sm={12} xs={12}>
                        <SVGWrapper>
                          <SVG svg={svg.location} />
                        </SVGWrapper>
                      </Col>
                    </Row>
                    <Row justify="center">
                      <h4>
                        <FormattedMessage id="contact.details.where_find" />
                      </h4>
                    </Row>
                  </div>
                  <Row className="contact-wrapper-infos-item" justify="center" gutter={[0, 24]}>
                    {loading ? (
                      <Col span={24}>
                        <Spin color={colors.grey} />
                      </Col>
                    ) : (
                      <>
                        <Col span={24}>
                          {address} <br />
                          {zipCode} {city}
                        </Col>
                        <Col span={24}>{email}</Col>
                        <Col span={24}>
                          {phone && phone.split('').map((x, i) => (i % 2 === 0 ? ` ${x}` : x))}
                        </Col>
                        <Col span={24}>
                          <FormattedMessage id="contact.details.only_appointment" />
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
              </Col>
              <Col>
                <div className="contact-wrapper-infos-network">
                  <div className="contact-wrapper-infos-title">
                    <Row justify="center">
                      <Col lg={6} sm={12} xs={12}>
                        <SVGWrapper>
                          <SVG svg={svg.network} />
                        </SVGWrapper>
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col>
                        <h4>
                          <FormattedMessage id="contact.network" />
                        </h4>
                      </Col>
                    </Row>
                  </div>

                  {loading ? (
                    <Row justify="center">
                      <Spin color={colors.red} />
                    </Row>
                  ) : (
                    <>
                      {facebookUrl && (
                        <NetworkLink
                          link={facebookUrl}
                          icon={ICONS_LIST.facebook}
                          name={facebookUrl
                            .split('/')
                            .filter((x) => x)
                            .pop()}
                        />
                      )}
                      {instagramUrl && (
                        <NetworkLink
                          link={instagramUrl}
                          icon={ICONS_LIST.instagram}
                          name={instagramUrl
                            .split('/')
                            .filter((x) => x)
                            .pop()}
                        />
                      )}
                    </>
                  )}
                </div>
              </Col>
            </div>
          </Col>
          <Col span={14}>
            <ContactForm />
          </Col>
        </div>
      )}
    </Layout>
  )
}

Contact.propTypes = {
  intl: PropTypes.shape().isRequired,
  contact: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  contact: state.contact,
  loading: loadingSelector([FETCH_CONTACT])(state)
})

const withIntl = injectIntl(Contact)
export default connect(mapStateToProps)(withIntl)
