import React from 'react'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'

import { Row, Col, ScrollLink, Svg } from 'lib'
import img from 'assets/images'
import svg from 'assets/svg'

import Logo from './_components/Logo'

const SVGWrapper = styled.div`
  width: 100%;
  padding: 10px;
`

const SVG = styled(Svg)`
  fill: #fff;
`

const Home = () => {
  return (
    <div id="home" className="home-container">
      <Row gutter={[16, { xs: 8, sm: 16, md: 20, lg: 20 }]} className="logo-wrapper">
        <Col xl={10} md={14} xs={19}>
          <Logo src={img.logoHead.url} name={img.logoHead.alt} animation="zoomIn" />
        </Col>
        <Col xl={10} md={14} xs={19}>
          <Logo src={img.logoFoot.url} name={img.logoFoot.alt} animation="bounceInLeft" />
        </Col>
      </Row>
      <Row className="icon-wrapper" justify="center">
        <Col xl={1} md={2} xs={4}>
          <ScrollLink linkTo="presentation">
            <SVGWrapper>
              <SVG svg={svg.mediator} />
            </SVGWrapper>
          </ScrollLink>
        </Col>
      </Row>
    </div>
  )
}

export default injectIntl(Home)
