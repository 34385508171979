import React from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import loadingSelector from 'utils/redux/selectors'
import { ALBUMS } from 'utils/route/constants'
import history from 'app/history'
import { Row, Col, Layout, PageTitle, Spin, GalleryCard, Icon, Divider, ICONS_LIST } from 'lib'
import { colors } from 'assets/themes'

import { FETCH_GALLERY } from 'store/constants'

const Gallery = ({ intl, gallery, loading }) => {
  const onClickMore = () => history.push(ALBUMS)

  return (
    <>
      <Layout id="gallery">
        <PageTitle
          title={intl.formatMessage({
            id: 'menu.gallery'
          })}
        />
        <div className="gallery">
          {loading ? (
            <Col span={24}>
              <Row justify="center">
                <Spin color={colors.red} size="large" margin />
              </Row>
            </Col>
          ) : (
            <Row gutter={[24, 24]} align="middle">
              {gallery.items.map((item) => (
                <Col key={item.id} lg={5} sm={12} xs={24}>
                  <GalleryCard element={item} />
                </Col>
              ))}
              <Col lg={4} sm={24} xs={24}>
                <button type="button" className="gallery-more-wrapper" onClick={onClickMore}>
                  <Col className="gallery-icon-wrapper">
                    <Icon name={ICONS_LIST.arrowRight} color={'#f5f5f5'} />
                  </Col>
                </button>
              </Col>
            </Row>
          )}
        </div>
      </Layout>
      <Divider margin={0} colors={colors.grey} height={12} />
    </>
  )
}

Gallery.propTypes = {
  intl: PropTypes.shape().isRequired,
  gallery: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  gallery: state.gallery,
  loading: loadingSelector([FETCH_GALLERY])(state)
})

const withIntl = injectIntl(Gallery)
export default connect(mapStateToProps)(withIntl)
