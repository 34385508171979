import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Menu, Footer } from 'lib'
import { fetchGallery, fetchContact } from 'store/actions'

import Home from './modules/home'
import Presentation from './modules/presentation'
import Services from './modules/services'
import Gallery from './modules/gallery'
import Contact from './modules/contact'

const HomePage = ({ actions }) => {
  useEffect(() => {
    actions.initData()
  }, [])

  return (
    <>
      <Menu />
      <Home />
      <Presentation />
      <Services />
      <Gallery />
      <Contact />
      <Footer />
    </>
  )
}

HomePage.propTypes = {
  actions: PropTypes.shape().isRequired
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    initData: () => {
      Promise.all([dispatch(fetchGallery()), dispatch(fetchContact())])
    }
  }
})

export default connect(null, mapDispatchToProps)(HomePage)
