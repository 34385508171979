import React from 'react'
import PropTypes from 'prop-types'
import { Element } from 'react-scroll'

import { BaseLayout } from 'lib'
import { colors } from 'assets/themes'

const { Content } = BaseLayout

const ContentLayout = ({ children, id }) => {
  return (
    <BaseLayout style={{ flex: '1', borderBottom: `15px solid ${colors.blue}` }}>
      <Content>
        <Element name={id}>
          <div className="container" id={id}>
            <div className="page-content">{children}</div>
          </div>
        </Element>
      </Content>
    </BaseLayout>
  )
}

ContentLayout.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default ContentLayout
