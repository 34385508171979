import englishFlag from './img/languages/english_flag.svg.png'
import frenchFlag from './img/languages/france_flag.svg.png'
import presentation from './img/photos/presentation.jpg'
import logo from './img/logo/logo.png'
import logoHead from './img/logo/logo_head.png'
import logoFoot from './img/logo/logo_foot.png'
import maintenance from './img/services/maintenance.jpg'
import elec from './img/services/elec.jpg'
import wood from './img/services/wood.jpg'
import fabrication from './img/services/fabrication.jpg'
import drive from './img/services/drive.jpg'
import other from './img/services/other.jpg'
import noImage from './img/no-image.jpg'

const img = {
  englishFlag: {
    url: englishFlag,
    alt: 'English Flag'
  },
  frenchFlag: {
    url: frenchFlag,
    alt: 'French Flag'
  },
  logo: {
    url: logo,
    alt: 'Logo'
  },
  logoHead: {
    url: logoHead,
    alt: 'Logo Head'
  },
  logoFoot: {
    url: logoFoot,
    alt: 'Logo Foot'
  },
  presentation: {
    url: presentation,
    alt: 'Atelier Brézé - Luthier'
  },
  maintenance: {
    url: maintenance,
    alt: 'Maintenance'
  },
  elec: {
    url: elec,
    alt: 'Electronics'
  },
  wood: {
    url: wood,
    alt: 'Wood'
  },
  fabrication: {
    url: fabrication,
    alt: 'Fabrication'
  },
  drive: {
    url: drive,
    alt: 'Drive'
  },
  other: {
    url: other,
    alt: 'Other'
  },
  noImage: {
    url: noImage,
    alt: 'noImage'
  }
}

export default img
