import React from 'react'
import { Switch } from 'react-router-dom'

import PrivateRoute from 'utils/route/private-route'
import * as constants from 'utils/route/constants'

import Home from './home'
import TermsConditionsOfUse from './terms-conditions-of-use'
import PrivacyPolicy from './privacy-policy'
import Albums from './albums/modules/albums'
import Publications from './albums/modules/galleries'

const routes = (
  <Switch>
    <PrivateRoute exact path={constants.TERMS_CONDITIONS_OF_USE} component={TermsConditionsOfUse} />
    <PrivateRoute exact path={constants.PRIVACY_POLICY} component={PrivacyPolicy} />
    <PrivateRoute exact path={constants.ALBUMS} component={Albums} />
    <PrivateRoute exact path={constants.PUBLICATIONS} component={Publications} />
    <PrivateRoute path={constants.HOME} component={Home} />
    {/** default always in last */}
  </Switch>
)

export default routes
