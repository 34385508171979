import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { useWindowWidth, isSizeBetween, BREAKPOINTS } from 'utils/tools/page'
import { Divider, Icon, ICONS_LIST, ICONS_MARGIN } from 'lib'
import { colors } from 'assets/themes'

const ServiceItem = (props) => {
  const { imgUrl, title, descriptions, position } = props
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(isSizeBetween(0, BREAKPOINTS.SM))
  }, [useWindowWidth()])

  return (
    <>
      <div
        className={`service-element service-element-${position}`}
        style={{ backgroundImage: `url(${imgUrl.url})` }}
      >
        {position === 'right' && (
          <div className="service-element-title service-element-title-right">
            <h3>{title}</h3>
            <Icon name={ICONS_LIST.plus} color={colors.white} />
          </div>
        )}

        {!isMobile && (
          <div className={`service-element-description service-element-description-${position}`}>
            {descriptions.map((item, i) => (
              <React.Fragment key={i}>
                <div>{item}</div>
                {i !== descriptions.length - 1 && <Divider colors={colors.grey} margin={2} opacity={0.5} />}
              </React.Fragment>
            ))}
          </div>
        )}
        {position === 'left' && (
          <div className="service-element-title service-element-title-left">
            <h3>{title}</h3>
            <Icon name={ICONS_LIST.plus} color={colors.white} margin={ICONS_MARGIN.right} />
          </div>
        )}
        {isMobile && (
          <div className={`service-element-description service-element-description-${position}`}>
            {descriptions.map((item, i) => (
              <React.Fragment key={i}>
                <div>{item}</div>
                {i !== descriptions.length - 1 && <Divider colors={colors.grey} margin={2} opacity={0.5} />}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

ServiceItem.propTypes = {
  imgUrl: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  descriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  position: PropTypes.oneOf(['left', 'right']).isRequired
}

export default injectIntl(ServiceItem)
