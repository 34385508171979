import React, { useEffect, useState, useCallback } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import svg from 'assets/svg'
import { Row, Col, ScrollLink, Icon, ICONS_LIST, Svg } from 'lib'
import { useWindowWidth, isSizeBetween, BREAKPOINTS } from 'utils/tools/page'
import { colors } from 'assets/themes'
import img from 'assets/images'
import lang from 'utils/intl/lang'
import { changeLanguage } from 'store/actions'

const SVGWrapper = styled.div`
  padding: 10px 10px;
  margin-left: 50px;
`

const SVG = styled(Svg)`
  fill: #fff;
`

const Menu = ({ intl, currentLang, actions }) => {
  const [showMinimalMenu, setShowMinimalMenu] = useState(false)
  const [overlayOpened, isOverlayOpened] = useState(false)
  const [navbarColored, isNavbarCollored] = useState(false)
  const openOverlay = useCallback(() => isOverlayOpened(true), [])
  const closeOverlay = useCallback(() => isOverlayOpened(false), [])
  const colorNavbar = useCallback(() => isNavbarCollored(true), [])
  const resetNavbar = useCallback(() => isNavbarCollored(false), [])

  useEffect(() => {
    setShowMinimalMenu(isSizeBetween(0, BREAKPOINTS.LG))
  }, [useWindowWidth()])

  const onScroll = () => {
    if (window.scrollY >= 120) {
      colorNavbar()
    } else {
      resetNavbar()
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return showMinimalMenu ? (
    <>
      <div className={overlayOpened ? 'menu-overlay menu-overlay-open' : 'menu-overlay'}>
        <Icon
          className="menu-overlay-close-btn"
          color={colors.white}
          name={ICONS_LIST.close}
          onClick={closeOverlay}
        />
        <div className="menu-overlay-content">
          <ScrollLink linkTo="home" onClick={closeOverlay}>
            {intl.formatMessage({ id: 'menu.home' })}
          </ScrollLink>
          <ScrollLink linkTo="presentation" onClick={closeOverlay}>
            {intl.formatMessage({ id: 'menu.presentation' })}
          </ScrollLink>
          <ScrollLink linkTo="services" onClick={closeOverlay}>
            {intl.formatMessage({ id: 'menu.services' })}
          </ScrollLink>
          <ScrollLink linkTo="gallery" onClick={closeOverlay}>
            {intl.formatMessage({ id: 'menu.gallery' })}
          </ScrollLink>
          {/* <ScrollLink linkTo="opinion" onClick={closeOverlay}>
            {intl.formatMessage({ id: 'menu.opinion' })}
          </ScrollLink> */}
          <ScrollLink linkTo="contact" onClick={closeOverlay}>
            {intl.formatMessage({ id: 'menu.contact' })}
          </ScrollLink>
        </div>
      </div>
      {!overlayOpened && (
        <div className="menu-overlay-open-btn">
          <Icon className="menu-icon" name={ICONS_LIST.menu} color={colors.white} onClick={openOverlay} />
        </div>
      )}
    </>
  ) : (
    <div className={navbarColored ? 'menu menu-active' : 'menu'}>
      <Row className="menu-content" justity="space-between" align="middle">
        <Col span={2}>
          <a href="https://atelierbreze.fr" className="menu-logo">
            <SVGWrapper>
              <SVG svg={svg.logo} />
            </SVGWrapper>
          </a>
        </Col>
        <Col span={18} offset={4}>
          <Row gutter={24} justity="space-between" align="middle">
            <Col span={3} offset={7}>
              <ScrollLink linkTo="home">{intl.formatMessage({ id: 'menu.home' })}</ScrollLink>
            </Col>
            <Col span={3}>
              <ScrollLink linkTo="presentation">{intl.formatMessage({ id: 'menu.presentation' })}</ScrollLink>
            </Col>
            <Col span={3}>
              <ScrollLink linkTo="services">{intl.formatMessage({ id: 'menu.services' })}</ScrollLink>
            </Col>
            <Col span={3}>
              <ScrollLink linkTo="gallery">{intl.formatMessage({ id: 'menu.gallery' })}</ScrollLink>
            </Col>
            {/* <Col span={3}>
              <ScrollLink linkTo="opinion">{intl.formatMessage({ id: 'menu.opinion' })}</ScrollLink>
            </Col> */}
            <Col span={3}>
              <ScrollLink linkTo="contact">{intl.formatMessage({ id: 'menu.contact' })}</ScrollLink>
            </Col>
            <Col span={2}>
              <div className="flag-wrapper" onClick={actions.onChangeLang} aria-hidden="true">
                {currentLang === lang.GB && <img src={img.frenchFlag.url} alt={img.frenchFlag.alt} />}
                {currentLang === lang.FR && <img src={img.englishFlag.url} alt={img.frenchFlag.alt} />}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

Menu.defaultProps = {
  currentLang: lang.FR
}

Menu.propTypes = {
  intl: PropTypes.shape().isRequired,
  actions: PropTypes.shape().isRequired,
  currentLang: PropTypes.string
}

const mapStateToProps = (state) => ({
  currentLang: state.language
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    onChangeLang: () => {
      dispatch((_, getState) => {
        const { language } = getState()
        dispatch(changeLanguage(language === lang.FR ? lang.GB : lang.FR))
      })
    }
  }
})

const withIntl = injectIntl(Menu)

export default connect(mapStateToProps, mapDispatchToProps)(withIntl)
