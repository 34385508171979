import styled from 'styled-components'
import { Input } from 'antd'
import { colors } from 'assets/themes'

const AntInputStyled = styled(Input)`
  border: none;
  border-bottom: 3px solid ${colors.red};
  border-radius: 0;
  padding: 0 0 10px 0;
  &:hover,
  &:focus {
    border: none;
    border-bottom: 3px solid ${colors.grey};
    border-radius: 0;
    padding: 0 0 10px 0;
    box-shadow: none;
  }
`

AntInputStyled.TextArea = styled(Input.TextArea)`
  border: none;
  border-bottom: 3px solid ${colors.red};
  border-radius: 0;
  padding: 0 0 10px 0;
  &:hover,
  &:focus {
    border: none;
    border-bottom: 3px solid ${colors.grey};
    border-radius: 0;
    padding: 0 0 10px 0;
    box-shadow: none;
  }
`

export default AntInputStyled
