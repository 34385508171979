import requestWrapper, { METHOD } from 'utils/redux/request'
import * as constants from './constants'

export const changeLanguage = (locale) => ({
  type: constants.CHANGE_LANGUAGE,
  payload: locale
})

export const fetchContact = () => async (dispatch, getState) => {
  const { contact } = getState()
  if (!Object.values(contact).length) {
    await dispatch(requestWrapper(METHOD.GET, '/contact', constants.FETCH_CONTACT))
  }
}

export const fetchGallery = () => async (dispatch, getState) => {
  const { gallery } = getState()
  if (gallery.total === null) {
    await dispatch(
      requestWrapper(METHOD.GET, '/publications', constants.FETCH_GALLERY, {
        limit: 4,
        offset: 0,
        total: true
      })
    )
  }
}

export const fetchAlbums = () => async (dispatch, getState) => {
  const { albums } = getState()
  if (albums.total === null) {
    await dispatch(requestWrapper(METHOD.GET, '/albums', constants.FETCH_ALBUMS, { total: true }))
  }
}

export const fetchAlbum = (id) => async (dispatch, getState) => {
  const { album } = getState()
  if (Number(album.id) !== Number(id)) {
    await dispatch(requestWrapper(METHOD.GET, `/albums/${id}`, constants.FETCH_ALBUM))
  }
}

export const fetchPublications = (albumId) => async (dispatch, getState) => {
  const { album, publications } = getState()
  if (Number(album.id) !== Number(albumId) || publications.total === null) {
    await dispatch(
      requestWrapper(METHOD.GET, '/publications', constants.FETCH_PUBLICATIONS, { total: true, albumId })
    )
  }
}
