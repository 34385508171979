export const CHANGE_LANGUAGE = 'language/CHANGE_LANGUAGE'
export const FETCH_CONTACT = 'contact/FETCH_CONTACT'
export const FETCH_GALLERY = 'gallery/FETCH_GALLERY'
export const FETCH_ALBUMS = 'gallery/FETCH_ALBUMS'
export const FETCH_ALBUM = 'gallery/FETCH_ALBUM'
export const FETCH_PUBLICATIONS = 'gallery/FETCH_PUBLICATIONS'

export const METHOD = {
  GET: 'get'
}

export const HTTP_BAD_REQUEST = 400
export const HTTP_NOT_FOUND = 404
